import { images } from "../source";

export const materialData = [
    [
      {
        id: 1,
        img: images.coating1.image,
        alt: images.coating1.alt,
        title: "Coatings",
        desc: "Exterior Coating",
        link: "/product/r-504",
      },
      {
        id: 2,
        img: images.coating2.image,
        alt: images.coating2.alt,
        title: "Coatings",
        desc: "Wood Finishes Coating",
        link: "/product/r-504",
      },
      {
        id: 3,
        img: images.coating3.image,
        alt: images.coating3.alt,
        title: "Coatings",
        desc: "Industrial spray coating",
        link: "/product/r-504",
      },
    ],
    [
      {
        id: 1,
        img: images.plastic1.image,
        alt: images.plastic1.alt,
        title: "Plastic",
        desc: "Masterbatch",
        link: "/product/r-702",
      },
      {
        id: 2,
        img: images.plastic2.image,
        alt: images.plastic2.alt,
        title: "Plastic",
        desc: "Plastic Sheets",
        link: "/product/r-702",
      },
      {
        id: 3,
        img: images.plastic3.image,
        alt: images.plastic3.alt,
        title: "Plastic",
        desc: "Plastic Bottles",
        link: "/product/r-702",
      },
    ],
    [
      {
        id: 1,
        img: images.ink1.image,
        alt: images.ink1.alt,
        title: "Inks",
        desc: "Gravure Inks",
        link: "/product/cr-801",
      },
      {
        id: 2,
        img: images.ink2.image,
        alt: images.ink2.alt,
        title: "Inks",
        desc: "Inkjet Inks",
        link: "/product/cr-801",
      },
      {
        id: 3,
        img: images.ink3.image,
        alt: images.ink3.alt,
        title: "Inks",
        desc: "Flexographic",
        link: "/product/cr-801",
      },
    ],
  ];