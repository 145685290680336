import { React, useEffect } from "react";
import "./terms.scss";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { faqBanner } from "../../source";
import { useWindowSize } from "react-use";
import Banner from "../../components/Banner/Banner";
import Sticky from "../../components/Sticky/Sticky";
import Whatsapp from "../../components/Whatsapp/Whatspp";

const Terms = () => {
  const { width } = useWindowSize();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <section className="terms_sec1" data-aos="fade">
        <Banner
          imgSrc={width > 767 ? faqBanner : faqBanner}
          linkTwoText="Terms & conditions"
          linkTwoDisabled
          headingText="Terms & conditions"
          textColor="#1B1B1B"
        />
      </section>

      <section className="terms_sec2" data-aos="fade-up">
        <div className="my_container">
          <p className="terms_content para_spacing" data-aos="fade-up">
            Effective Date: [7th June 2023]
          </p>
          <p className="terms_content" data-aos="fade-up">
            Please read these Terms and Conditions ("Terms") carefully before
            engaging in any business transactions with Titax, a pre-eminent
            supplier of Titanium Dioxide ("we," "us," or "our"). These Terms
            govern your use of our products and services. By placing an order,
            making a purchase, or engaging in any business transaction with
            Titax, you agree to be bound by these Terms. If you do not agree
            with these Terms, please refrain from using our products and
            services.
          </p>
          <div className="content_wrapper">
            <h2 className="terms_heading" data-aos="fade-up">Product Information</h2>
            <p className="terms_content" data-aos="fade-up">
              We strive to provide accurate and up-to-date information about our
              products. However, the information we provide, including product
              descriptions, specifications, pricing, and availability, is
              subject to change without prior notice. We do not warrant that the
              information provided is error-free, complete, or current.
            </p>
          </div>
          <div className="content_wrapper">
            <h2 className="terms_heading" data-aos="fade-up">Orders and Payments</h2>
            <p className="terms_content" data-aos="fade-up">
              By placing an order or making a purchase, you agree to provide
              accurate and complete information. All orders are subject to
              acceptance and availability. We reserve the right to refuse or
              cancel any order at our sole discretion, without liability. In the
              event of order cancellation, any payment received will be
              refunded.
            </p>
          </div>
          <div className="content_wrapper">
            <h2 className="terms_heading" data-aos="fade-up">Product Use and Safety</h2>
            <p className="terms_content" data-aos="fade-up">
              Our products, specifically Titanium Dioxide (TiO2), are intended
              for use in accordance with applicable laws and regulations. It is
              your responsibility to ensure that the products are used in a safe
              and compliant manner. We shall not be held liable for any misuse
              or improper handling of our products.
            </p>
          </div>
          <div className="content_wrapper">
            <h2 className="terms_heading" data-aos="fade-up">Product Quality and Warranty</h2>
            <p className="terms_content" data-aos="fade-up">
              We take pride in providing high-quality Titanium Dioxide products.
              However, due to the nature of industrial processes and
              applications, we cannot guarantee specific results or outcomes. It
              is recommended to conduct appropriate testing and evaluation to
              determine the suitability of our products for your intended use.
              We do not provide any warranty, express or implied, regarding the
              performance, fitness for a particular purpose, or merchantability
              of our products.
            </p>
          </div>
          <div className="content_wrapper">
            <h2 className="terms_heading" data-aos="fade-up">Limitation of Liability</h2>
            <p className="terms_content" data-aos="fade-up">
              To the maximum extent permitted by applicable law, in no event
              shall Titax be liable for any direct, indirect, incidental,
              consequential, special, or punitive damages arising out of or in
              connection with the use or inability to use our products, even if
              advised of the possibility of such damages. Our liability shall be
              limited to the purchase price of the product in question.
            </p>
          </div>
          <div className="content_wrapper">
            <h2 className="terms_heading" data-aos="fade-up">Intellectual Property</h2>
            <p className="terms_content" data-aos="fade-up">
              All intellectual property rights, including trademarks, logos, and
              product names, associated with Titax products are owned by or
              licensed to us. You shall not reproduce, modify, distribute, or
              exploit any part of our intellectual property without our prior
              written consent.
            </p>
          </div>
          <div className="content_wrapper">
            <h2 className="terms_heading" data-aos="fade-up">Confidentiality</h2>
            <p className="terms_content" data-aos="fade-up">
              Any confidential or proprietary information shared between Titax
              and you during the course of business transactions shall be
              treated as confidential. You agree not to disclose or use such
              information for any purpose other than the intended business
              relationship, unless required by law.
            </p>
          </div>
          <div className="content_wrapper">
            <h2 className="terms_heading" data-aos="fade-up">Governing Law and Jurisdiction</h2>
            <p className="terms_content" data-aos="fade-up">
              These Terms shall be governed by and construed in accordance with
              the laws of the jurisdiction where Titax is located. Any disputes
              arising out of or relating to these Terms or the use of our
              products and services shall be subject to the exclusive
              jurisdiction of the courts in that jurisdiction.
            </p>
          </div>
          <div className="content_wrapper">
            <h2 className="terms_heading" data-aos="fade-up">Severability</h2>
            <p className="terms_content" data-aos="fade-up">
              If any provision of these Terms is found to be invalid, illegal,
              or unenforceable, the remaining provisions shall continue in full
              force and effect.
            </p>
          </div>
          <div className="content_wrapper">
            <h2 className="terms_heading" data-aos="fade-up">Entire Agreement</h2>
            <p className="terms_content" data-aos="fade-up">
              These Terms constitute the entire agreement between you and Titax
              regarding the use of our products and services and supersede any
              prior agreements or understandings, whether written or oral.
            </p>
          </div>
        </div>
      </section>

      {width < 767 ? <Sticky /> : null}
      <Whatsapp />
      <Footer />
    </>
  );
};

export default Terms;
