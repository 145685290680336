import { React, useEffect, useState } from "react";
import "./contactus.scss";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { call, email, faqBanner, location } from "../../source";
import { useWindowSize } from "react-use";
import Banner from "../../components/Banner/Banner";
import Sticky from "../../components/Sticky/Sticky";
import config from "../../config/config";
import Joi from "joi";
import http from "../../helpers/http";
import { useNavigate } from "react-router-dom";
import { thanyouURL } from "../../helpers/paths";
import Whatsapp from "../../components/Whatsapp/Whatspp";

const Contactus = () => {
  const { width } = useWindowSize();

  const [values, setValues] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [formError, setFormError] = useState({});
  const [loading, setLoading] = useState(false);

  let base_url = config.api_url + "/forms/contact";

  const navigate = useNavigate();

  const schema = Joi.object({
    name: Joi.string().trim().required().label("Name"),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .label("Email"),
    phone: Joi.number().required().label("Contact Number"),
  }).options({ allowUnknown: true });

  const validate = async (data) => {
    const { error } = schema.validate(data, {
      abortEarly: false,
      allowUnknown: true,
      errors: {
        wrap: {
          label: "",
        },
      },
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    return errors ? errors : null;
  };

  const handleSubmit = async (e) => {
    console.log("form submit");

    e.preventDefault();
    // console.log(values);
    let errors = await validate(values);
    setFormError(errors);
    if (Object.keys(errors).length) {
      return;
    }

    setLoading(true);

    // let formData = new FormData();
    // for (const [key, data] of Object.entries(
    //   _.omit(values, ["document", "phone"])
    // )) {
    //   formData.append(key, data || "");
    // }

    // if (values.phone) {
    //   formData.append("phone", `${countryCode}${values.phone}`);
    // }
    // if (values.document) {
    //   formData.append("document", values.document);
    // }

    // console.log(...formData);
    // return;

    const { data } = await http.post(base_url, values);

    if (data) {
      // redirect
      // navigate("/thank-you");
      setValues({
        name: "",
        email: "",
        phone: "",
        message: "",
      });
      navigate(thanyouURL);
    }
    setLoading(false);
  };

  const handleChange = (e) => {
    if (e.target.type === "file") {
      console.log(e.target.files);
      setValues((prevState) => {
        return {
          ...prevState,
          [e.target.name]: e.target.files[0],
        };
      });
    } else {
      setValues((prevState) => {
        return {
          ...prevState,
          [e.target.name]: e.target.value,
        };
      });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <section className="contact_sec1" data-aos="fade">
        <Banner
          imgSrc={width > 767 ? faqBanner : faqBanner}
          linkTwoText="Contact us"
          linkTwoDisabled
          headingText="Contact us"
          textColor="#1B1B1B"
        />
      </section>
      <section className="contact_sec2" data-aos="fade-up">
        <div className="my_container">
          <h2 className="contact_title" data-aos="fade-up">
            Where to Find Us
          </h2>
          <div className="row" data-aos="fade-up">
            <div className="col-md-4 contact_col" data-aos="fade-up">
              <img src={location} alt="circle" className="contact_icon" />
              <h2 className="contact_heading" data-aos="fade-up">
                Work Office
              </h2>
              <a
                href="https://goo.gl/maps/p23PNuLyrioxe6Vp6"
                target="_blank"
                rel="noreferrer"
                className="contact_details"
                data-aos="fade-up"
              >
                CWEP0148 Compass Building, Al Shohada Road, AL Hamra Industrial
                Zone-FZ, Ras Al Khaimah, Arab Emirates.
              </a>
            </div>
            <div className="col-md-4 contact_col" data-aos="fade-up">
              <img
                src={email}
                alt="circle"
                className="contact_icon"
                data-aos="fade-up"
              />
              <h2 className="contact_heading" data-aos="fade-up">
                Email ID
              </h2>
              <a
                href="mailto:sales@titaxtitanium.com"
                className="contact_details"
                data-aos="fade-up"
              >
                sales@titaxtitanium.com
              </a>
            </div>
            <div className="col-md-4 contact_col" data-aos="fade-up">
              <img
                src={call}
                alt="circle"
                className="contact_icon"
                data-aos="fade-up"
              />
              {width > 767 ? (
                <h2 className="contact_heading" data-aos="fade-up">
                  Contact No
                </h2>
              ) : (
                <h2 className="contact_heading" data-aos="fade-up">
                  Call Us
                </h2>
              )}
              <p className="contact_details" data-aos="fade-up">
                <a href="tel:+971552563311">+971 55 256 3311</a>
                {/* <span> | </span> */}
                <br />
                {/* <a href="tel:+91989854754">+ 91 989854754</a> */}
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="contact_sec3" data-aos="fade-up">
        <div className="my_container">
          <iframe
            title="titax google map location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3596.189919982625!2d55.7862928!3d25.6649983!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ef60bee567a253b%3A0x80fd444807765d7!2sRAKEZ%20Compass%20Coworking!5e0!3m2!1sen!2suk!4v1685947908266!5m2!1sen!2suk"
            width="100%"
            height={width > 767 ? 566 : 253}
            style={{ border: "0", margin: "0", padding: "0" }}
            loading="lazy"
          ></iframe>
        </div>
      </section>
      <section className="contact_sec4" data-aos="fade-up">
        <div className="my_container">
          <div className="row" data-aos="fade-up">
            <div className="col-lg-4 details_col" data-aos="fade-up">
              <h2 className="left_heading">Inquire now</h2>
              <p className="left_content">
                Fill in the form to get in touch with our team for any business
                and general enquiries.
              </p>
            </div>
            <form
              className="col-lg-8 details_col"
              onSubmit={handleSubmit}
              data-aos="fade-up"
            >
              <div className="row contact_row2" data-aos="fade-up">
                <div className="col-md-6 form-floating contact_col2">
                  <input
                    type="text"
                    className="form-control contact_input"
                    // id="floatingtext"
                    placeholder="Name*"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                  />
                  <label for="floatingtext">Name*</label>
                  <div className="error">{formError.name}</div>
                </div>
                <div className="col-md-6 form-floating contact_col2">
                  <input
                    type="email"
                    class="form-control contact_input"
                    // id="floatingtext"
                    placeholder="Email ID*"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                  />
                  <label for="floatingtext">Email ID*</label>
                  <div className="error">{formError.email}</div>
                </div>
                <div className="col-md-6 form-floating contact_col2">
                  <input
                    type="tel"
                    class="form-control contact_input"
                    // id="floatingtext"
                    placeholder="Phone Number*"
                    name="phone"
                    value={values.phone}
                    onChange={handleChange}
                  />
                  <label for="floatingtext">Phone Number*</label>
                  <div className="error">{formError.phone}</div>
                </div>

                {/* <div className="col-md-6 form-floating contact_col2">
                  <select className="contact_input contact_catergory">
                    <option value="Category">Category</option>
                    <option value="Category1">Category1</option>
                    <option value="Category2">Category2</option>
                    <option value="Category3">Category3</option>
                  </select>

                  <div className="error">{formError.category}</div>
                </div> */}

                <div className="col-md-6 form-floating contact_col2">
                  <textarea
                    className="form-control contact_input contact_msg "
                    placeholder="Message"
                    id="floatingTextarea"
                    name="message"
                    value={values.message}
                    onChange={handleChange}
                  ></textarea>
                  <label for="floatingTextarea">Message</label>
                  <div className="error">{formError.message}</div>
                </div>

                <div className="col-md-6 contact_col2">
                  <div
                    className="cta_wrapper"
                    data-aos="fade-up"
                    data-aos-delay="500"
                  >
                    <button type="submit" className="about-button">
                      {loading ? "Loading.." : "Submit"}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
      {width < 767 ? <Sticky /> : null};
      <Whatsapp />
      <Footer />
    </>
  );
};

export default Contactus;
