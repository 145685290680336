import React, { useEffect } from "react";
import "./aboutus.scss";
import Header from "../../components/Header/Header";
import Sticky from "../../components/Sticky/Sticky";
import Footer from "../../components/Footer/Footer";
import { useWindowSize } from "react-use";
import Banner from "../../components/Banner/Banner";
import {
  aboutExcellent,
  aboutHigh,
  aboutHighHeat,
  aboutLow,
  aboutLowMositure,
  aboutStable,
  aboutusBanner,
  images,
  leftarrow,
  locationIcon,
  rightarrow,
  tio2,
  whitearrowleft,
  whitearrowright,
} from "../../source";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import Enquiry from "../../components/Enquiry/Enquiry";
import { Link } from "react-router-dom";
import { contactusURL } from "../../helpers/paths";
import Whatsapp from "../../components/Whatsapp/Whatspp";

const uspData = [
  {
    id: 1,
    image: images.aboutQuality.image,
    alt: images.aboutQuality.alt,
    heading: "Quality",
    desc: "Our products are developed to cater to global standards",
  },
  {
    id: 2,
    image: images.aboutInnovation.image,
    alt: images.aboutInnovation.alt,
    heading: "Innovation & Development",
    desc: "Our uncompromising focus on product development & help us to drive excellence all around the world",
  },
  {
    id: 3,
    image: images.aboutReliability.image,
    alt: images.aboutReliability.alt,
    heading: "Reliability & Assurance",
    desc: "Stable and dependable quality for our customers",
  },
  {
    id: 4,
    image: images.aboutTrust.image,
    alt: images.aboutTrust.alt,
    heading: "Trust",
    desc: "With over <strong>55 years of expertise</strong>, knowledge and innovation, we are known for being a trusted supplier of Titanium Dioxide",
  },
];

const uspData1 = [
  {
    id: 1,
    image: images.aboutQuality.image,
    alt: images.aboutQuality.alt,
    heading: "Quality",
    desc: "Our products are developed to cater to global standards",

    image1: images.aboutInnovation.image,
    alt1: images.aboutInnovation.alt,
    heading1: "Innovation & Development",
    desc1:
      "Our uncompromising focus on product development & help us to drive excellence all around the world",
  },
  {
    id: 2,
    image: images.aboutReliability.image,
    alt: images.aboutReliability.alt,
    heading: "Reliability & Assurance",
    desc: "Stable and dependable quality for our customers",

    image1: images.aboutTrust.image,
    alt1: images.aboutTrust.alt,
    heading1: "Trust",
    desc1:
      "With over <strong>55 years of expertise</strong>, knowledge and innovation, we are known for being a trusted supplier of Titanium Dioxide",
  },
];

const Aboutus = () => {
  const { width } = useWindowSize();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />

      <section className="about_sec1" data-aos="fade">
        <Banner
          imgSrc={width > 767 ? aboutusBanner : aboutusBanner}
          linkTwoText="About us"
          linkTwoDisabled
          headingText="About us"
        />
      </section>

      <section className="about_sec2" data-aos="fade-up">
        <div className="my_container">
          <div className="row about_row" data-aos="fade-up">
            <div className="col-md-6 about_col" data-aos="fade-up">
              <h2 className="subheading" data-aos="fade-up">
                {/* We at Titax are a pre-eminent supplier of Titanium Dioxide */}
                Leading Provider of High-Quality Titanium Dioxide Solutions
              </h2>
            </div>
            <div className="col-md-6 about_col"></div>
            <div className="col-md-6 about_col" data-aos="fade-up">
              <p className="text" data-aos="fade-up">
                At Titax, our mission is to drive lasting industry
                transformations with our technical expertise, innovative
                research, and European-engineered technology. With strategic
                collaborations spanning decades, we empower customers and
                partners to excel in competitive markets, setting new standards
                for reliability. With over <strong>55 years</strong> {""}
                of experience in the titanium dioxide industry, we offer a
                strong portfolio of high-performance products tailored to
                various needs like paints, plastics, paper, and ink. Our
                commitment to superior quality and consistent performance is
                reflected in every product, giving our clients the confidence to
                enhance their market offerings.
              </p>
            </div>
            <div className="col-md-6 about_col" data-aos="fade-up">
              <p className="text" data-aos="fade-up">
                At Titax, we lead in product innovation and sustainability as
                global pioneers. Our skilled team drives milestones in TiO2
                supply chain and development. With an expanding international
                presence, our vision is to be a top global TiO2 supplier. We
                value creating a brighter, more efficient, and stable future.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="about_sec3" data-aos="fade-up">
        <div className="my_container">
          <div className="row tio_row" data-aos="fade-up">
            <div className="col-md-6 tio_col" data-aos="fade-up">
              <h2 className="subheading" data-aos="fade-up">
                {" "}
                About TiO2
              </h2>
              <div className="row tio_icon_row" data-aos="fade-up">
                <div className="col-md-4 col-6 tio_icon_col">
                  <img
                    src={aboutExcellent}
                    alt="Excellent"
                    className="tio_icon"
                    data-aos="fade-up"
                  />
                  <p className="tio_icon_text" data-aos="fade-up">
                    Excellent levelling property
                  </p>
                </div>
                <div className="col-md-4 col-6 tio_icon_col">
                  <img
                    src={aboutLow}
                    alt="Excellent"
                    className="tio_icon"
                    data-aos="fade-up"
                  />
                  <p className="tio_icon_text" data-aos="fade-up">
                    Low oil absorption
                  </p>
                </div>
                <div className="col-md-4 col-6 tio_icon_col">
                  <img
                    src={aboutStable}
                    alt="Excellent"
                    className="tio_icon"
                    data-aos="fade-up"
                  />
                  <p className="tio_icon_text" data-aos="fade-up">
                    Stabilised quality
                  </p>
                </div>
                <div className="col-md-4 col-6 tio_icon_col">
                  <img
                    src={aboutHigh}
                    alt="Excellent"
                    className="tio_icon"
                    data-aos="fade-up"
                  />
                  <p className="tio_icon_text" data-aos="fade-up">
                    High opacity and whiteness
                  </p>
                </div>
                <div className="col-md-4 col-6 tio_icon_col">
                  <img
                    src={aboutHighHeat}
                    alt="Excellent"
                    className="tio_icon"
                    data-aos="fade-up"
                  />
                  <p className="tio_icon_text" data-aos="fade-up">
                    High heat stability
                  </p>
                </div>
                <div className="col-md-4 col-6 tio_icon_col">
                  <img
                    src={aboutLowMositure}
                    alt="Excellent"
                    className="tio_icon"
                    data-aos="fade-up"
                  />
                  <p className="tio_icon_text" data-aos="fade-up">
                    Low moisture absorption
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 tio_col">
              <p className="text" data-aos="fade-up">
                Titanium dioxide (TiO2) is a prominent white pigment found in
                numerous daily products. Its brilliant white hue,
                light-reflecting properties, and UV protection make it a
                favorite in both industrial and consumer markets. Valued for its
                safe, non-reactive, and radiant qualities, TiO2 boosts the
                brightness and whiteness of many materials. It's commonly used
                in items like paints, plastics, sunscreens, packaging, and
                commercial inks, among others.
              </p>
              <div className="tio2_img_container">
                <img src={tio2} alt="Tio2" className="tio2_img" />
              </div>
              <div className="tio2_cta" data-aos="fade-up">
                <div className="cta_wrapper">
                  <Link to={contactusURL} className="about-button">
                    Enquire Now
                  </Link>
                </div>
                <div className="product_cta_wrapper">
                  <Link to="/product/r-504" className="about-button">
                    View our product
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about_sec4" data-aos="fade-up">
        <div className="my_container">
          <div className="quality_row" data-aos="fade-up">
            <Swiper
              className="uspSwiper"
              slidesPerView={4}
              modules={[Navigation]}
              autoHeight={true}
              navigation={{
                nextEl: ".right_arrow",
                prevEl: ".left_arrow",
              }}
              breakpoints={{
                0: {
                  spaceBetween: 0,
                  slidesPerView: 1,
                },
                768: {
                  spaceBetween: 60,
                  slidesPerView: 2,
                },
                992: {
                  spaceBetween: 50,
                  slidesPerView: 4,
                },
                1280: {
                  spaceBetween: 80,
                  slidesPerView: 4,
                },
                1920: {
                  spaceBetween: 111,
                  slidesPerView: 4,
                },
                2250: {
                  spaceBetween: 120,
                  slidesPerView: 4,
                },
              }}
            >
              {width > 767
                ? uspData.map((item, i) => (
                    <SwiperSlide key={i}>
                      <div className="quality_col" data-aos="fade-up">
                        <img
                          src={item.image}
                          alt={item.alt}
                          className="quality_img"
                          data-aos="fade-up"
                        />

                        {/* <div className="socialicons">
                          <div className="socialicon">
                            <div className="dribbblecolor socialiconcircle1"></div>
                            <div className="socialiconcircle2">
                              <img
                                src={business}
                                className="icons fa-dribbble dribbble"
                              />
                            </div>
                          </div>
                        </div> */}

                        <h2 className="quality_heading" data-aos="fade-up">
                          {item.heading}
                        </h2>
                        <p
                          className="text"
                          data-aos="fade-up"
                          dangerouslySetInnerHTML={{ __html: item.desc }}
                        ></p>
                      </div>
                    </SwiperSlide>
                  ))
                : uspData1.map((item, i) => (
                    <SwiperSlide>
                      <div className="quality_col" data-aos="fade-up">
                        <img
                          src={item.image}
                          alt={item.alt}
                          className="quality_img"
                          data-aos="fade-up"
                        />
                        <h2 className="quality_heading" data-aos="fade-up">
                          {item.heading}
                        </h2>
                        <p
                          className="text"
                          data-aos="fade-up"
                          dangerouslySetInnerHTML={{ __html: item.desc }}
                        ></p>
                      </div>

                      <div className="quality_col_1" data-aos="fade-up">
                        <img
                          src={item.image1}
                          alt={item.alt1}
                          className="quality_img"
                          data-aos="fade-up"
                        />
                        <h2 className="quality_heading" data-aos="fade-up">
                          {item.heading1}
                        </h2>
                        <p
                          className="text"
                          data-aos="fade-up"
                          dangerouslySetInnerHTML={{ __html: item.desc1 }}
                        ></p>
                      </div>
                    </SwiperSlide>
                  ))}
              {width <= 834 ? (
                <div className="mbarrows_wrapper">
                  <img
                    src={leftarrow}
                    className="left_arrow"
                    alt="arrow"
                    loading="lazy"
                    onMouseOver={(e) => (e.currentTarget.src = whitearrowleft)}
                    onMouseOut={(e) => (e.currentTarget.src = leftarrow)}
                  />
                  <img
                    src={rightarrow}
                    className="right_arrow"
                    alt="arrow"
                    loading="lazy"
                    onMouseOver={(e) => (e.currentTarget.src = whitearrowright)}
                    onMouseOut={(e) => (e.currentTarget.src = rightarrow)}
                  />
                </div>
              ) : null}
            </Swiper>
          </div>
        </div>
      </section>

      <section className="about_sec5" data-aos="fade-up">
        <div className="my_container">
          <div className="footprint_container">
            <div className="row footprint_row" data-aos="fade-up">
              <div className="col-md-6 footprint_col" data-aos="fade-up">
                <h2 className="subheading" data-aos="fade-up">
                  Our Global Footprint
                </h2>
              </div>
              <div className="col-md-6 footprint_col" data-aos="fade-up">
                <p className="text" data-aos="fade-up">
                  Our warehouses are strategically positioned to assist our
                  clients in better managing their businesses. These location
                  provides a great impetus for effectively managing the
                  distribution.
                </p>
              </div>
            </div>

            <div className="row location_row" data-aos="fade-up">
              <div
                className="col-lg-3 col-md-6 col-6 location_col"
                data-aos="fade-up"
              >
                <div className="location_wrapper">
                  <img
                    src={locationIcon}
                    alt="location icon"
                    className="location_img"
                    data-aos="fade-up"
                  />
                  <p className="country" data-aos="fade-up">
                    SOUTH <br /> AMERICA
                  </p>
                </div>
              </div>
              <div
                className="col-lg-3 col-md-6 col-6 location_col"
                data-aos="fade-up"
              >
                <div className="location_wrapper">
                  <img
                    src={locationIcon}
                    alt="location icon"
                    className="location_img"
                    data-aos="fade-up"
                  />
                  <p className="country" data-aos="fade-up">
                    MIDDLE <br /> EAST
                  </p>
                </div>
              </div>
              <div
                className="col-lg-3 col-md-6 col-6 location_col"
                data-aos="fade-up"
              >
                <div className="location_wrapper">
                  <img
                    src={locationIcon}
                    alt="location icon"
                    className="location_img"
                    data-aos="fade-up"
                  />
                  <p className="country" data-aos="fade-up">
                    AFRICA
                  </p>
                </div>
              </div>
              <div
                className="col-lg-3 col-md-6 col-6 location_col"
                data-aos="fade-up"
              >
                <div className="location_wrapper">
                  <img
                    src={locationIcon}
                    alt="location icon"
                    className="location_img"
                    data-aos="fade-up"
                  />
                  <p className="country" data-aos="fade-up">
                    ASIA <br /> PACIFIC
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="about_sec6" data-aos="fade-up">
        <div className="my_container">
          <div className="team_wrapper" data-aos="fade-up">
            <h2 className="subheading" data-aos="fade-up">Meet our team</h2>
            <p className="text" data-aos="fade-up">
              The team at Titax is a tight-knit, talented group with a shared
              vision of delivering consistently great results and services for
              our clients. Their uncompromised commitment to quality,
              sustainability, innovation and technology has positioned them to
              set new benchmarks.
            </p>
          </div>
        </div>
      </section> */}

      {/* <section className="about_sec7">
        <div className="my_container">
          <div className="team_slider_row" data-aos="fade-up">
            <Swiper
              className="teamSwiper"
              slidesPerView={3}
              spaceBetween={54}
              modules={[Navigation]}
              navigation={{
                nextEl: ".team_right_arrow",
                prevEl: ".team_left_arrow",
              }}
              breakpoints={{
                0: {
                  spaceBetween: 0,
                  slidesPerView: 1,
                },
                768: {
                  spaceBetween: 50,
                  slidesPerView: 2,
                },
                993: {
                  spaceBetween: 54,
                  slidesPerView: 3,
                },
                1280: {
                  spaceBetween: 54,
                  slidesPerView: 3,
                },
                1920: {
                  spaceBetween: 54,
                  slidesPerView: 3,
                },
                2250: {
                  spaceBetween: 120,
                  slidesPerView: 3,
                },
              }}
            >
              <SwiperSlide>
                <div className="team_slide_col" data-aos="fade-up">
                  <img
                    src={personIcon}
                    alt="person icon"
                    className="person_img" data-aos="fade-up"
                  />
                  <h2 className="team_name" data-aos="fade-up">Mr. Balkrishna Popawala</h2>
                  <p className="text" data-aos="fade-up">Chairman</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="team_slide_col" data-aos="fade-up">
                  <img
                    src={personIcon}
                    alt="person icon"
                    className="person_img" data-aos="fade-up"
                  />
                  <h2 className="team_name" data-aos="fade-up">Mr. Hiren Popawala</h2>
                  <p className="text" data-aos="fade-up">Mr. Hiren Popawala</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="team_slide_col" data-aos="fade-up">
                  <img
                    src={personIcon}
                    alt="person icon"
                    className="person_img" data-aos="fade-up"
                  />
                  <h2 className="team_name" data-aos="fade-up">Mr. Akshay Popawala</h2>
                  <p className="text" data-aos="fade-up">Director</p>
                </div>
              </SwiperSlide>
            </Swiper>
            {width > 992 ? null : (
              <div className="team_arrow">
                <img
                  src={leftarrow}
                  alt="left arrow"
                  className="team_left_arrow"
                  onMouseOver={(e) => (e.currentTarget.src = whitearrowleft)}
                  onMouseOut={(e) => (e.currentTarget.src = leftarrow)}
                />
                <img
                  src={rightarrow}
                  alt="right arrow"
                  className="team_right_arrow"
                  onMouseOver={(e) => (e.currentTarget.src = whitearrowright)}
                  onMouseOut={(e) => (e.currentTarget.src = rightarrow)}
                />
              </div>
            )}
          </div>
        </div>
      </section> */}
      <Enquiry />
      <Sticky />
      <Whatsapp />
      <Footer />
    </>
  );
};

export default Aboutus;
