import { images } from "../source";
import _ from "lodash";

export const productDataList = [
  {
    id: 1,
    heading: "R-504",
    // tabHeading: "R-504 <br /> Plastic grade | <br /> Sulphate process",
    tabHeading: "R-504 <br /> Plastic grade",
    slug: "r-504",
    // subheading: "Plastic grade | Sulphate process",
    subheading: "Plastic grade",
    title: "Recommended applications",
    details: `<li>• Polyolefin • PVC • ABS <span class="list_block"> • Masterbatch and compounds</span></li>
               <li>• Plastics in high thermal stability</li>`,
    feature: "Key features :",
    img: images.R504.image,
    alt: images.R504.alt,
    description: "Description :",
    descText: `R-504, a rutile titanium dioxide, is meticulously manufactured using the sulphate process. Through careful control of the hydrolysis and calcination processes, we ensure a consistent particle size using advanced grinding techniques. With specialized surface treatment, this product demonstrates low oil absorption and superior levelling properties. The quality of R-504, offered by Titax, is consistently exceptional and stable.`,
    keyfeature: [
      {
        id: 1,
        img: images.excellent.image,
        alt: images.excellent.alt,
        text: "Excellent levelling property",
      },
      {
        id: 2,
        img: images.low.image,
        alt: images.low.alt,
        text: "Low oil absorption",
      },
      {
        id: 3,
        img: images.stable.image,
        alt: images.stable.alt,
        text: "Stabilised quality",
      },
      {
        id: 4,
        img: images.high.image,
        alt: images.high.alt,
        text: "High opacity and whiteness",
      },
      {
        id: 5,
        img: images.highHeat.image,
        alt: images.highHeat.alt,
        text: "High heat stability",
      },
      {
        id: 6,
        img: images.lowMoisture.image,
        alt: images.lowMoisture.alt,
        text: "Low moisture absorption",
      },
    ],
    table: [
      {
        id: 1,
        parameter: "TiO2 Content (%)",
        value: "≥97.0",
      },
      {
        id: 2,
        parameter: "Brightness (%)",
        value: "≥95.1",
      },
      {
        id: 3,
        parameter: "Tinting Strength",
        value: "≥1950",
      },
      {
        id: 4,
        parameter: "pH value",
        value: "6.0~8.5",
      },
      {
        id: 5,
        parameter: "Oil absorption (g/100g)",
        value: "≤15",
      },
      {
        id: 6,
        parameter: "325 mesh sieve residue (%)",
        value: "≤0.01",
      },
      {
        id: 7,
        parameter: "Resistivity (ohm-meter)",
        value: "≥80",
      },
      {
        id: 8,
        parameter: "Oil dispersion (H)",
        value: "≥6.0",
      },
      {
        id: 9,
        parameter: "Volatile rate at 105°C",
        value: "≤0.5",
      },
      {
        id: 10,
        parameter: "Inorganic coating",
        value: "Zr AI",
      },
      {
        id: 11,
        parameter: "Organic treatment",
        value: "Yes",
      },
    ],
  },
  {
    id: 2,
    heading: "R-702",
    tabHeading:
      // "R-702 <br /> Paint and coating grade | <br /> Sulphate process",
      "R-702 <br /> Paint and coating grade",

    slug: "r-702",
    // subheading: "Paint grade | Sulphate process",
    subheading: "Paint grade",
    title: "Recommended applications",
    details: `<li>• Industrial coatings</li>
               <li>• Powder coatings</li>
               <li>• Interior and exterior architectural coatings</li>`,
    feature: "Key features :",
    img: images.R702.image,
    alt: images.R702.alt,
    description: "Description :",
    descText: `R-702 is a rutile titanium dioxide made by sulphate process. It has an
              unparalleled hiding power due to the unique hydrolysis and calcination
              process. It has a uniform particle size distribution and excellent properties
              in gloss and opacity. A special surface treatment grants R-702 with better
              weather resistance, lower oil absorption and better levelling property.`,
    keyfeature: [
      {
        id: 1,
        img: images.excellent.image,
        alt: images.excellent.alt,
        text: "Excellent levelling property",
      },
      {
        id: 2,
        img: images.dispersibility.image,
        alt: images.dispersibility.alt,
        text: "Good Dispersibility",
      },
      {
        id: 3,
        img: images.high.image,
        alt: images.high.alt,
        text: "High Opacity and Brightness",
      },
      {
        id: 4,
        img: images.low.image,
        alt: images.low.alt,
        text: "Low oil absorption",
      },
      {
        id: 5,
        img: images.weather.image,
        alt: images.weather.alt,
        text: "Excellent Weather Resistance",
      },
    ],
    table: [
      {
        id: 1,
        parameter: "TiO2 Content (%)",
        value: "≥93.0",
      },
      {
        id: 2,
        parameter: "Brightness (%)",
        value: "≥95.1",
      },
      {
        id: 3,
        parameter: "Tinting Strength",
        value: "≥1980",
      },
      {
        id: 4,
        parameter: "pH value",
        value: "6.0~8.5",
      },
      {
        id: 5,
        parameter: "Oil absorption (g/100g)",
        value: "≤18",
      },
      {
        id: 6,
        parameter: "325 mesh sieve residue (%)",
        value: "≤0.01",
      },
      {
        id: 7,
        parameter: "Resistivity (ohm-meter)",
        value: "≥80",
      },
      {
        id: 8,
        parameter: "Oil dispersion (H)",
        value: "≥6.0",
      },
      {
        id: 9,
        parameter: "Volatile rate at 105°C",
        value: "≤0.5",
      },
      {
        id: 10,
        parameter: "Inorganic coating",
        value: "Zr AI",
      },
      {
        id: 11,
        parameter: "Organic treatment",
        value: "Yes",
      },
    ],
  },
  {
    id: 3,
    heading: "CR-801",
    tabHeading:
      // "CR-801 <br /> Paint and coating grade | <br /> Chloride process",
      "CR-801 <br /> Paint and coating grade",

    slug: "cr-801",
    // subheading: "Coating grade | Chloride process",
    subheading: "Coating grade",
    title: "Recommended applications",
    details: `<li>• Industrial coatings</li>
               <li>• Interior architectural coatings</li>
               <li>• Exterior architectural coatings</li>`,
    feature: "Key features :",
    img: images.R801.image,
    alt: images.R801.alt,
    description: "Description :",
    descText: `CR-801 is a zirconia and alumina inorganic and organic surface treaded rutile TiO2 pigment from the chlorination process. It has high glossiness, nice wetting and dispersion, good weathering resistance and high hiding power properties. Is is especially used in outdoor water based paint with high weather resistance, oilpaint and ink.`,
    keyfeature: [
      {
        id: 1,
        img: images.hiding.image,
        alt: images.hiding.alt,
        text: "Excellent Hiding Power Property",
      },
      {
        id: 2,
        img: images.dispersibility.image,
        alt: images.dispersibility.alt,
        text: "Good Dispersibility",
      },
      {
        id: 3,
        img: images.Opacity.image,
        alt: images.Opacity.alt,
        text: "High Opacity and Gloss",
      },
      {
        id: 4,
        img: images.weather.image,
        alt: images.weather.alt,
        text: "Excellent Weather Resistance",
      },
    ],
    table: [
      {
        id: 1,
        parameter: "Crystal Structure",
        value: "Rutile",
      },
      {
        id: 2,
        parameter: "ISO 691 and DIN Classification",
        value: "R2",
      },
      {
        id: 3,
        parameter: "ASTM D476 Classification",
        value: "|||",
      },
      {
        id: 4,
        parameter: "Chemical Abstracts Number (%)",
        value: "13463-67-7",
      },
      {
        id: 5,
        parameter: "TiO2 Content (%)",
        value: "≥93.5",
      },
      {
        id: 6,
        parameter: "Brightness (%)",
        value: "≥97.0",
      },
      {
        id: 7,
        parameter: "pH value",
        value: "6.0~8.0",
      },
      {
        id: 8,
        parameter: "Oil absorption (g/100g)",
        value: "≤20",
      },
      {
        id: 9,
        parameter: "Tinting Strength (Reynolds number)",
        value: "≥1950",
      },
      {
        id: 10,
        parameter: "Specific resistance (Ω-m)",
        value: "≥60",
      },
      {
        id: 11,
        parameter: "Density (g/cm³)",
        value: "≥4.1",
      },
      {
        id: 12,
        parameter: "Average Particle Size (µm)",
        value: "≤0.30",
      },
      {
        id: 13,
        parameter: "Surface Treatment",
        value: "ZrO2; i2O3; organic",
      },
    ],
  },
  {
    id: 4,
    heading: "A-315",
    tabHeading:
      "A-315 <br /> Titanium dioxide fiber grade",

    slug: "a-315",
    subheading: "Fiber grade",
    title: "Recommended applications",
    details: ``,
    feature: "Key features :",
    img: images.A301.image,
    alt: images.A301.alt,
    description: "Description :",
    descText: `High-grade chemical fiber titanium dioxide, organic and inorganic processed with good
    dispersion in water and ethylene glycol. It has fine particle size, low iron content and low
    hardness, also performs the high whiteness, high achromatic strength, high brightness
    and short grinding time as the advanced chemical fiber titanium dioxide. `,
    keyfeature: [
      {
        id: 1,
        img: images.dispersibility.image,
        alt: images.dispersibility.alt,
        text: "Good water dispersion",
      },
      {
        id: 2,
        img: images.fineParticle.image,
        alt: images.fineParticle.alt,
        text: "Fine particle size",
      },
      {
        id: 3,
        img: images.Opacity.image,
        alt: images.Opacity.alt,
        text: "High whiteness",
      },
      {
        id: 4,
        img: images.highAcromatic.image,
        alt: images.highAcromatic.alt,
        text: "High Achromatic strength",
      },
    ],
    table: [
      {
        id: 1,
        parameter: "TiO2 Content (%)",
        value: "≥98.5",
      },
      {
        id: 2,
        parameter: "Hue L",
        value: "≥90",
      },
      {
        id: 3,
        parameter: "Hue a",
        value: "≤1",
      },
      {
        id: 4,
        parameter: "Hue b",
        value: "≤3",
      },
      {
        id: 5,
        parameter: "Power of fading color",
        value: "≥1500",
      },
      {
        id: 6,
        parameter: "PH value",
        value: "6.5~7.5",
      },
      {
        id: 7,
        parameter: "Oil absorption (g/100g)",
        value: "≤20",
      },
      {
        id: 8,
        parameter: "Volatile rate at 105°C",
        value: "≤0.5",
      },
      {
        id: 9,
        parameter: "Whiteness of dry powder (%)",
        value: "≥96",
      },
      {
        id: 10,
        parameter: "45um Residue (%)",
        value: "≤0.01",
      },
      {
        id: 11,
        parameter: "Water Soluble (%)",
        value: "≤0.5",
      },
      {
        id: 12,
        parameter: "Water Dispersion (%)",
        value: "≥94.5",
      },
      {
        id: 13,
        parameter: "Electrical Resistivity (Ω·m) ",
        value: "≥80",
      },
      {
        id: 14,
        parameter: "Fe203 (%)",
        value: "≤0.001",
      },
      {
        id: 15,
        parameter: "Intrinsic Viscosity",
        value: "0.645±0.013",
      },
      {
        id: 16,
        parameter: "Softening Point (°C)",
        value: "262.5±1.2",
      },
      {
        id: 17,
        parameter: "Free Carboxyl (mol/L)",
        value: "≤33",
      },
      {
        id: 18,
        parameter: "L",
        value: "65 ± 4",
      },
      {
        id: 19,
        parameter: "b",
        value: "64.5 ± 2",
      },
      {
        id: 13,
        parameter: "Condensation Particle (≥5um)",
        value: "≤5",
      },
    ],
  },
  {
    id: 5,
    heading: "A-900",
    tabHeading:
      "A-900 <br /> Titanium dioxide anatase grade",

    slug: "a-900",
    subheading: "Anatase grade",
    title: "Recommended applications",
    details: `<li>• High grade colour Masterbatch</li>
               <li>• Printing Ink</li>
               <li>• Paper</li>
               <li>• Chemical Fiber</li>`,
    feature: "Key features :",
    img: images.A900.image,
    alt: images.A900.alt,
    description: "Description :",
    descText: `This product is of high purity. The appearance is white and a little bit blue and
    shows good gloss. The grains of which are soft, uniform. The product shows high
    electronic resistivity, low water absorbing capacity, low percentage of iron
    content and heavy metals, and scattering well in ethylene glycol and water.`,
    keyfeature: [
      {
        id: 1,
        img: images.Opacity.image,
        alt: images.Opacity.alt,
        text: "High opacity and gloss",
      },
      {
        id: 2,
        img: images.resistivity.image,
        alt: images.resistivity.alt,
        text: "High electronic resistivity",
      },
      {
        id: 3,
        img: images.low.image,
        alt: images.low.alt,
        text: "Low water absorbing capacity",
      },
      {
        id: 4,
        img: images.lowIron.image,
        alt: images.lowIron.alt,
        text: "Low iron content",
      },
      {
        id: 5,
        img: images.dispersibility.image,
        alt: images.dispersibility.alt,
        text: "Good dispersibility",
      },
    ],
    table: [
      {
        id: 1,
        parameter: "Percentage of TiO2 (%)",
        value: "≥98.5",
      },
      {
        id: 2,
        parameter: "Oil Phase L*",
        value: "≥0.1",
      },
      {
        id: 3,
        parameter: "Oil Phase b*",
        value: "≤0.2",
      },
      {
        id: 4,
        parameter: "Power of fading colour",
        value: "≥110",
      },
      {
        id: 5,
        parameter: "Volatile rate at 105°C",
        value: "≤0.5",
      },
      {
        id: 6,
        parameter: "Water soluble (%)",
        value: "≤0.5",
      },
      {
        id: 7,
        parameter: "Electrical resistivity",
        value: "≥24",
      },
      {
        id: 8,
        parameter: "325 Visible residue (%)",
        value: "≤0.03",
      },
      {
        id: 9,
        parameter: "pH Value",
        value: "6.5~8.5",
      },
      {
        id: 10,
        parameter: "Oil absorption (g/100g)",
        value: "≤20",
      },
    ],
  },
  {
    id: 6,
    heading: "CR-522",
    tabHeading:
      "CR-522 <br /> Titanium dioxide plastic grade",

    slug: "cr-522",
    subheading: "Plastic grade",
    title: "Recommended applications",
    details: `<li>• High-grade masterbatch</li>
               <li>• Plastic</li>
               <li>• PVC</li>`,
    feature: "Key features :",
    img: images.CR522.image,
    alt: images.CR522.alt,
    description: "Description :",
    descText: `CR-522 is a rutile titanium dioxide pigment produced by advanced chloride
    process and treated by special inorganic and organic processes. The product
    gains high cover, good dispersibility, excellent dry powder fluidity and excellent
    processing performance by special surface treatment technology. Its a unique
    polyolefin masterbatch type titanium dioxide.`,
    keyfeature: [
      {
        id: 1,
        img: images.dispersibility.image,
        alt: images.dispersibility.alt,
        text: "Good dispersibility",
      },
      {
        id: 2,
        img: images.dryPowder.image,
        alt: images.dryPowder.alt,
        text: "Excellent dry powder fluidity",
      },
      {
        id: 3,
        img: images.processingPerformance.image,
        alt: images.processingPerformance.alt,
        text: "Excellent processing performance",
      },
    ],
    table: [
      {
        id: 1,
        parameter: "TiO2 Content (%)",
        value: "≥96.0",
      },
      {
        id: 2,
        parameter: "Rutile conversion rate",
        value: "≥99.0",
      },
      {
        id: 3,
        parameter: "PH value",
        value: "6.5~8.5",
      },
      {
        id: 4,
        parameter: "Absorb capacity (g/100g)",
        value: "≤16",
      },
      {
        id: 5,
        parameter: "Volatile rate at 105°C",
        value: "≤0.3",
      },
      {
        id: 6,
        parameter: "Tinting strength",
        value: "≥1900",
      },
      {
        id: 7,
        parameter: "Oil phase lightness (L*)",
        value: "≥97",
      },
      {
        id: 8,
        parameter: "Tint (SCX)",
        value: "4.5~5.5",
      },
      {
        id: 9,
        parameter: "CIE Whiteness",
        value: "≥90.0",
      },
      {
        id: 10,
        parameter: "Electrical Resistivity (Ω·m)",
        value: "≥90",
      },
    ],
  },
  {
    id: 7,
    heading: "R-414",
    tabHeading:
      "R-414 <br /> Titanium dioxide specialised grade",

    slug: "r-414",
    subheading: "Specialised grade",
    title: "Recommended applications",
    details: `<li>• Coatings</li>
               <li>• Plastics</li>
               <li>• Ink</li>
               <li>• Paper</li>`,
    feature: "Key features :",
    img: images.R414.image,
    alt: images.R414.alt,
    description: "Description :",
    descText: `R-414 is a rutile titanium dioxide pigment with alumina, Zirconia and organic
    surface treatment, with high whiteness and gloss, high scattering power, high
    durability and excellent dispersibility. As a general purpose Tio2, widely used in
    coatings, plastics, ink and paper`,
    keyfeature: [
      {
        id: 1,
        img: images.whitenessGloss.image,
        alt: images.whitenessGloss.alt,
        text: "High whiteness and gloss",
      },
      {
        id: 2,
        img: images.highScattering.image,
        alt: images.highScattering.alt,
        text: "High scattering power",
      },
      {
        id: 3,
        img: images.highDurability.image,
        alt: images.highDurability.alt,
        text: "High durability",
      },
      {
        id: 4,
        img: images.dispersibility.image,
        alt: images.dispersibility.alt,
        text: "Excellent dispersibility",
      },
    ],
    table: [
      {
        id: 1,
        parameter: "TiO2 Content (%)",
        value: "≥93.5",
      },
      {
        id: 2,
        parameter: "Brightness (%)",
        value: "≥94.5",
      },
      {
        id: 3,
        parameter: "Tinting Power",
        value: "≥1850",
      },
      {
        id: 4,
        parameter: "Volatile rate at 105°C %",
        value: "≤0.5",
      },
      {
        id: 5,
        parameter: "Matter Soluble in water %",
        value: "≤0.5",
      },
      {
        id: 6,
        parameter: "PH value",
        value: "6.5~8.5",
      },
      {
        id: 7,
        parameter: "Oil absorption (g/100g)",
        value: "≤20",
      },
      {
        id: 8,
        parameter: "Fineness (45um sieve residue) %",
        value: "≤0.02",
      },
      {
        id: 9,
        parameter: "Resistivity (Ω·m)",
        value: "≥80",
      },
      {
        id: 10,
        parameter: "Oil dispersion (H)",
        value: "≥5.5",
      },
      {
        id: 11,
        parameter: "L",
        value: "≥100.0",
      },
      {
        id: 12,
        parameter: "b",
        value: "≤1.80",
      },
    ],
  },
  {
    id: 8,
    heading: "R-603",
    tabHeading:
      "R-603 <br /> Titanium dioxide plastic grade",

    slug: "r-603",
    subheading: "Plastic grade",
    title: "Recommended applications",
    details: `<li>• Polyolefin</li>
               <li>• PVC</li>
               <li>• ABS</li>
               <li>• Masterbatch and compounds</li>
               <li>• Plastics in high thermal stability</li>`,
    feature: "Key features :",
    img: images.R603.image,
    alt: images.R603.alt,
    description: "Description :",
    descText: `R-603 is an excellent rutile titanium dioxide produced by a new technical Sulphate
    process. With professionally designed and controlled in hydrolysis and calcination
    process, the particle size of R-603 is more uniform under advanced and finely grounding
    technical. R-603 owns lower oil absorption and better levelling property through the
    special surface treatment. With our company’s large-scaled producing equipment,
    advanced DCS control system, high technical and experienced management R-603
    exhibits outstanding and stabilized quality.`,
    keyfeature: [
      {
        id: 1,
        img: images.high.image,
        alt: images.high.alt,
        text: "High opacity and whiteness",
      },
      {
        id: 2,
        img: images.lowMoisture.image,
        alt: images.lowMoisture.alt,
        text: "Low oil absorption",
      },
      {
        id: 3,
        img: images.excellent.image,
        alt: images.excellent.alt,
        text: "Excellent levelling property",
      },
      {
        id: 4,
        img: images.highHeat.image,
        alt: images.highHeat.alt,
        text: "High heat stability",
      },
      {
        id: 5,
        img: images.lowMoisture.image,
        alt: images.lowMoisture.alt,
        text: "Low moisture absorption",
      },
    ],
    table: [
      {
        id: 1,
        parameter: "Rutile Content (%)",
        value: "≥96.0",
      },
      {
        id: 2,
        parameter: "TiO2 Content (%)",
        value: "≥96.0",
      },
      {
        id: 3,
        parameter: "Brightness (%)",
        value: "≥95.1",
      },
      {
        id: 4,
        parameter: "Tinting Strength",
        value: "≥1950",
      },
      {
        id: 5,
        parameter: "PH value",
        value: "6.0~8.5",
      },
      {
        id: 6,
        parameter: "Oil absorption (g/100g)",
        value: "≤15",
      },
      {
        id: 7,
        parameter: "325 mesh sieve residue (%)",
        value: "≤0.01",
      },
      {
        id: 8,
        parameter: "Resistivity (Ω·m)",
        value: "≥80",
      },
      {
        id: 9,
        parameter: "Oil dispersion (H)",
        value: "≥6.0",
      },
      {
        id: 10,
        parameter: "Volatile rate at 105°C",
        value: "≤0.5",
      },
      {
        id: 11,
        parameter: "Inorganic coating",
        value: "Zr Al",
      },
      {
        id: 12,
        parameter: "Organic treatment",
        value: "Yes",
      },
    ],
  },
  {
    id: 9,
    heading: "R-612",
    tabHeading:
      "R-612 <br /> Titanium dioxide plastic grade",

    slug: "r-612",
    subheading: "Plastic grade",
    title: "Recommended applications",
    details: `<li>• Engineering Polymers</li>
               <li>• Syrene Polymers</li>
               <li>• PVC (Flexible)</li>
               <li>• ABS</li>
               <li>• Polyethylene</li>
               <li>• Masterbatch</li>`,
    feature: "Key features :",
    img: images.R612.image,
    alt: images.R612.alt,
    description: "Description :",
    descText: ``,
    keyfeature: [
      {
        id: 1,
        img: images.lowMoisture.image,
        alt: images.lowMoisture.alt,
        text: "Low moisture",
      },
      {
        id: 2,
        img: images.dispersibility.image,
        alt: images.dispersibility.alt,
        text: "Good dispersability",
      },
      {
        id: 3,
        img: images.blueUndertone.image,
        alt: images.blueUndertone.alt,
        text: "Blue undertone",
      },
      {
        id: 4,
        img: images.stable.image,
        alt: images.stable.alt,
        text: "Good stability",
      },
      {
        id: 5,
        img: images.Opacity.image,
        alt: images.Opacity.alt,
        text: "High opacity",
      },
    ],
    table: [
      {
        id: 1,
        parameter: "TiO2 Content (%)",
        value: "≥97.0",
      },
      {
        id: 2,
        parameter: "Rutile Content (%)",
        value: "≥98.0",
      },
      {
        id: 3,
        parameter: "Tinting Strength (TCS)",
        value: "Min. 1800",
      },
      {
        id: 4,
        parameter: "Undertone (SCX)",
        value: "≥3.5",
      },
      {
        id: 5,
        parameter: "Volatile rate at 105°C",
        value: "0.20",
      },
      {
        id: 6,
        parameter: "Sieve Residue 45um (mg/kg)",
        value: "≤10",
      },
      {
        id: 7,
        parameter: "Resistivity (Ω·m)",
        value: "≥100",
      },
      {
        id: 8,
        parameter: "Oil absorption (g/100g)",
        value: "≤15",
      },
    ],
  },
];

export const findProductBySlug = (slug) => {
  return _.find(productDataList, { slug: slug });
};

export const findPrevProductSlug = (current_slug) => {
  const currentIndex = _.findIndex(productDataList, { slug: current_slug });
  if (currentIndex === 0) {
    return productDataList[currentIndex].slug;
  }
  return productDataList[currentIndex - 1].slug;
};

export const findNextProductSlug = (current_slug) => {
  const currentIndex = _.findIndex(productDataList, { slug: current_slug });
  const lastIndex = productDataList.length - 1;
  if (currentIndex === lastIndex) {
    return productDataList[currentIndex].slug;
  }
  return productDataList[currentIndex + 1].slug;
};
