import React, { useEffect, useState } from "react";
import "./industries.scss";
import Header from "../../components/Header/Header";
import Sticky from "../../components/Sticky/Sticky";
import Footer from "../../components/Footer/Footer";
import { useWindowSize } from "react-use";
import Banner from "../../components/Banner/Banner";
import {
  industriesBanner,
  mbindustriesBanner,
  usp1Img,
  usp2Img,
  whitearrowleft,
  whitearrowright,
  yellowleft,
  yellowright,
} from "../../source";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import Enquiry from "../../components/Enquiry/Enquiry";
import Table from "../../components/Table/Table";
import { materialData } from "../../Data/industriesData";
import { Link } from "react-router-dom";
import TableOld from "../../components/TableOld/TableOld";
import Whatsapp from "../../components/Whatsapp/Whatspp";

const tabsArr = [
  { id: 1, title: "Coatings" },
  { id: 2, title: "Plastic" },
  { id: 3, title: "Inks" },
];

const Industries = () => {
  const { width } = useWindowSize();
  const [rightList, setRightList] = useState(0);
  // const appListLength = materialData.length;

  useEffect(() => {
    window.scrollTo(0, 0);
    // window.history.scrollRestoration = 'manual'
  }, []);

  const leftTabsList = tabsArr.length
    ? tabsArr.map((item, i) => (
        <li
          key={item.id}
          className={`lefttab ${rightList === i ? "active" : null}`}
          onClick={() => setRightList(i)}
        >
          {item.title}
        </li>
      ))
    : null;

  const mbleftTabsList = tabsArr.map(({ title }, i) => (
    <option value={title} key={i} data-applicationindexid={i}>
      {title}
    </option>
  ));

  const handleApplicationTabChange = (e) => {
    const applicationIndexId =
      e.target.selectedOptions[0].dataset.applicationindexid;
    setRightList(Number(applicationIndexId));
  };

  const materialList = materialData[rightList].map((item, i) => (
    <>
      <SwiperSlide key={i}>
        <div className="material_slider" data-aos="fade-up">
          <div className="img_wrapper">
            <img
              src={item.img}
              alt="material"
              loading="lazy"
              className="material_img img-fluid"
              data-aos="fade-up"
            />
          </div>
          <div className="material_details" data-aos="fade-up">
            <h2 className="material_title" data-aos="fade-up">
              {" "}
              {item.title}
            </h2>
            <p className="material_desc" data-aos="fade-up">
              {item.desc}
            </p>
            <Link to={item.link} className="material_link" data-aos="fade-up">
              View product
            </Link>
          </div>
        </div>
      </SwiperSlide>
    </>
  ));

  return (
    <>
      <Header />

      <section className="ind_sec1" data-aos="fade">
        <Banner
          imgSrc={width > 767 ? industriesBanner : mbindustriesBanner}
          linkTwoText="Industries & Applications"
          linkTwoDisabled
          headingText="Industries & Applications"
        />
      </section>

      <section className="ind_sec2" data-aos="fade-up">
        <div className="my_container">
          <div className="row ind_row" data-aos="fade-up">
            <div className="col-md-5 ind_col" data-aos="fade-up">
              <h2 className="subheading" data-aos="fade-up">
                Range of TiO2 Application
              </h2>
            </div>
            <div className="col-md-7 ind_col" data-aos="fade-up">
              <p className="text" data-aos="fade-up">
                Well-Weighted Portfolio of TiO2 Grades in Sulphate and Chloride
                Processes. TiO2 has applications for coatings, plastics as well
                as inks.
              </p>
              <div className="cta_wrapper" data-aos="fade-up">
                <Link to="/product/r-504" className="about-button">
                  View our products
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="ind_sec3" data-aos="fade-up">
        <div className="my_container">
          {/* tab section start */}
          <div className="row" data-aos="fade-up">
            {width > 767 ? (
              <div className="left_section" data-aos="fade-up">
                <ul>{leftTabsList}</ul>
              </div>
            ) : (
              <div className="mb_dropdown">
                <select
                  className="mb_select"
                  onChange={handleApplicationTabChange}
                >
                  {mbleftTabsList}
                </select>
              </div>
            )}
            <div className="material_swiper">
              <Swiper
                className="materialSwiper"
                slidesPerView={3}
                slidesPerGroup={1}
                spaceBetween={60}
                modules={[Navigation]}
                navigation={{
                  nextEl: ".material_right_arrow",
                  prevEl: ".material_left_arrow",
                }}
                breakpoints={{
                  0: {
                    spaceBetween: 20,
                    slidesPerView: 1,
                  },
                  768: {
                    spaceBetween: 50,
                    slidesPerView: 2,
                  },
                  992: {
                    spaceBetween: 40,
                    slidesPerView: 3,
                  },
                  1280: {
                    spaceBetween: 60,
                    slidesPerView: 3,
                  },
                  1920: {
                    spaceBetween: 60,
                    slidesPerView: 3,
                  },
                  2100: {
                    spaceBetween: 90,
                    slidesPerView: 3,
                  },
                }}
              >
                {materialList}
              </Swiper>
              {width > 834 ? null : (
                <div className="material_arrow">
                  <img
                    src={yellowleft}
                    alt="left arrow"
                    className="material_left_arrow"
                    onMouseOver={(e) => (e.currentTarget.src = whitearrowleft)}
                    onMouseOut={(e) => (e.currentTarget.src = yellowleft)}
                  />
                  <img
                    src={yellowright}
                    alt="right arrow"
                    className="material_right_arrow"
                    onMouseOver={(e) => (e.currentTarget.src = whitearrowright)}
                    onMouseOut={(e) => (e.currentTarget.src = yellowright)}
                  />
                </div>
              )}
            </div>
          </div>
          {/* tab section end */}
        </div>
      </section>

      {/* <TableOld /> */}
      <Table />

      <section className="ind_sec4" data-aos="fade-up">
        <div className="my_container">
          <div className="row usp_row" data-aos="fade-up">
            <div className="col-lg-6 usp_col" data-aos="fade-up">
              <img
                src={usp1Img}
                alt="aim"
                className="ind_usp_img"
                data-aos="fade-up"
              />
              <p className="usp_head" data-aos="fade-up">
                Well-weighted portfolio of TiO2 grades in sulphate and chloride
                processes
              </p>
              <ul className="usp_list" data-aos="fade-up">
                <li>
                  Multiple grades that matches a wide range of applications for
                  our customers
                </li>
                <li>
                  European technology developed and tailored for global markets
                </li>
              </ul>
            </div>

            <div className="col-lg-6 usp_col" data-aos="fade-up">
              <img src={usp2Img} alt="well wieghted" className="ind_usp_img" />
              <p className="usp_head" data-aos="fade-up">
                We aim to extend our global presence and expand our reach to new
                markets.
              </p>
              <ul className="usp_list" data-aos="fade-up">
                <li>
                  Distinguished product approach to suit a wide range of
                  preferences
                </li>
                <li>
                  Reliable, reputed partners and agents for consistent and
                  effective product offering
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <Enquiry />
      <Sticky />
      <Whatsapp />
      <Footer />
    </>
  );
};

export default Industries;
