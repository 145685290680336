import React from 'react';
import logo from '../../source/images/Coming Soon/Group 4398.svg';
import banner from '../../source/images/Coming Soon/bannerdesktop.png';
import bannermob from '../../source/images/Coming Soon/bannermob1.png';
import rectangle from '../../source/images/Coming Soon/Group 4400@2x.png';
import rectanglemob from '../../source/images/Coming Soon/textbannermob.png';
import { useWindowSize } from "react-use";
import '../ComingSoonPage/Comingsoon.scss';

const Comingsoon = () => {
  const { width: windowWidth } = useWindowSize();
  return (
    <>
     {windowWidth > 1023 ? (
      <>
        <div className="logo-container">
          <img src={logo} alt="" />
        </div>

        <div className="comingsoon_sec">
          <div className="comingsoonbanner">
            <img src={banner} alt="" />
          </div>
          <div className="comingsoontext">
            <img src={rectangle} alt="" />
          </div>    
        </div>
      </>
     ) : (
      <>
        <div className="logo-container">
          <img src={logo} alt="" />
        </div>

        <div className="comingsoon_sec">
          <div className="comingsoonbanner">
            <img src={bannermob} alt="" />
          </div>
          <div className="comingsoontext">
            <img src={rectangle} alt="" />
          </div>    
        </div>
    </>
     )
     }
    </>
  )
}

export default Comingsoon