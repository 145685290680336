import React, { useEffect } from "react";
import "./thankyou.scss";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { mbthankYou, thankYou } from "../../source";
import { useWindowSize } from "react-use";
import { homeURL } from "../../helpers/paths";
import Whatsapp from "../../components/Whatsapp/Whatspp";

const Thankyou = () => {
    const { width } = useWindowSize();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

  return (
    <>
      <Header />
      <section className="thank_sec1">
        <div className="row thank_row">
          <div className="col-md-7 thank_col">
          {width > 767 ? (
            <img src={thankYou} alt="Thank YOu" className="thank_img" />
          ) :
          (
            <img src={mbthankYou} alt="Thank YOu" className="mbthank_img" />
          )}
          </div>
          <div className="col-md-5 thank_col">
            <div className="thank_text_wrapper">
              <h2 className="thank_heading">THANK YOU</h2>
              <p className="thank_text">
                YOUR MESSAGE HAS BEEN RECEIVED, WE WILL UPDATE YOU SHORTLY.
              </p>
              <div className="cta_wrapper">
                <a href={homeURL} className="common_cta">
                Return to the website
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Whatsapp />
      <Footer />
    </>
  );
};
export default Thankyou;
