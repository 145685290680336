export const homeURL = "/";
export const aboutusURL = "/about-us";
export const productsURL = "/product";
export const industriesURL = "/industries-&-applications";
export const newsRoomURL = "/newsroom";
export const newsInnerURL = "/newsroom/:slug";
export const contactusURL = "/contact-us";
export const faqURL = "/faq";
export const termsURL = "/terms-&-conditions";
export const privacyURL = "/privacy-policy";
export const thanyouURL = "/thank-you";
export const pageNotFound = "/404";
export const comingsoonURL = "/coming-soon";
