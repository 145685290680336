import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Banner.scss";
import { aboutusBanner } from "../../source";
import { boolean } from "joi";
// import Loader from "../../../../popawala-front/src/components/Loader/Loader";

const Banner = ({
  imgSrc,
  linkTwoText,
  linkTwoDisabled,
  headingText,
  textColor = "#ffffff",
}) => {
  // const [ textColor, setTextColor ] = useState();

  //   const [isLoader, setIsLoader] = useState(true);
  //   useEffect(() => {
  //     window.scrollTo(0, 0);
  //   }, [isLoader]);

  //   const imageLoaded = (e) => {
  //     if (e.target.complete && e.target.naturalHeight !== 0) {
  //       setIsLoader(false);
  //     }
  //   };

  return (
    <>
      {/* <div style={{ display: isLoader ? "block" : "none" }}>
        <Loader />
      </div> */}
      {/* <section className="common_banner_wrapper" data-aos="fade"> */}
      <section className="common_banner_wrapper">
        <img
          src={imgSrc}
          alt="About us banner"
          loading="lazy"
          className="common_bannerimg"
          // onLoad={imgSrc ? imageLoaded : null}
        />
        <div className="my_container">
          <div className="breadcrumb_content_wrapper">
            <div className="text_animation_down">
              <Link
                to="/"
                className="breadcrumb_text"
                style={{ color: textColor }}
              >
                home
              </Link>
              {linkTwoText && (
                <Link
                  to="/"
                  className={`breadcrumb_text ${
                    linkTwoDisabled ? "disabled" : ""
                  }`}
                  style={{ color: textColor }}
                >
                  &nbsp;|&nbsp;
                  {linkTwoText}
                </Link>
              )}
            </div>
            <h1
              className="banner_heading text_animation_up"
              style={{ color: textColor }}
            >
              {headingText}
            </h1>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;
