import React, { useEffect, useState } from "react";
import "./enquiry.scss";
import { whitearrowright, wright } from "../../source";
import { useWindowSize } from "react-use";
import { thanyouURL } from "../../helpers/paths";
import { useNavigate } from "react-router-dom";
import http from "../../helpers/http";
import Joi from "joi";
import config from "../../config/config";

const Enquiry = () => {
  const { width } = useWindowSize();

  const [values, setValues] = useState({
    name: "",
    email: "",
  });
  const [formError, setFormError] = useState({});
  const [loading, setLoading] = useState(false);

  let base_url = config.api_url + "/forms/enquiry";
  // let sizeLimit = 2;

  const navigate = useNavigate();

  const schema = Joi.object({
    name: Joi.string().trim().required().label("Name"),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .label("Email ID"),
  }).options({ allowUnknown: true });

  const validate = async (data) => {
    const { error } = schema.validate(data, {
      abortEarly: false,
      allowUnknown: true,
      errors: {
        wrap: {
          label: "",
        },
      },
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    return errors ? errors : null;
  };

  const handleSubmit = async (e) => {
    console.log("form submit");

    e.preventDefault();
    // console.log(values);
    let errors = await validate(values);
    setFormError(errors);
    console.log({errors})
    if (Object.keys(errors).length) {
      return;
    }

    setLoading(true);

    const { data } = await http.post(base_url, values);

    if (data) {
      setValues({
        name: "",
        email: "",
      });
      navigate(thanyouURL);
    }
    setLoading(false);
  };

  const handleChange = (e) => {
    if (e.target.type === "file") {
      console.log(e.target.files);
      setValues((prevState) => {
        return {
          ...prevState,
          [e.target.name]: e.target.files[0],
        };
      });
    } else {
      setValues((prevState) => {
        return {
          ...prevState,
          [e.target.name]: e.target.value,
        };
      });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section className="enquiry_sec1" data-aos="fade-up">
        <div className="my_container">
          <div className="row contact_row">
            <h2 className="heading" data-aos="fade-up">CONTACT US</h2>
            <div className="col-md-3 contact_col contact_md3" data-aos="fade-up">
              <p className="subheading" data-aos="fade-up">Get in Touch</p>
              {/* <p className="subheading">Get in touch with us for any business related queries.</p> */}
            </div>
            <form
              className="col-md-9 contact_col contact_md8"
              onSubmit={handleSubmit}
            >
              <div className="row contact_row2">
                <div className="col-md-5 contact_col2 form-floating">
                  <input
                    type="text"
                    class="form-control contact_input"
                    id="floatingtext"
                    placeholder="Name*"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                  />
                  <label for="floatingtext">Name*</label>
                  <div className="error">{formError.name}</div>
                </div>
                <div className="col-md-5 contact_col2 form-floating">
                  <input
                    type="email"
                    class="form-control contact_input"
                    id="floatingemail"
                    placeholder="Email ID*"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                  />
                  <label for="floatingemail">Email ID*</label>
                  <div className="error">{formError.email}</div>
                </div>
                {width > 992 ? ( 
                <div className="col-md-1 contact_col">
                <button type="submit" className="desk_btn">
                  {loading ? (
                    <div className="spinner-grow text-dark" role="status">
                      {/* <span className="sr-only">Loading...</span> */} <img src={wright} alt="Right arrow" className="spinner_img"/>
                    </div>
                  ) : (
                    <img
                    src={whitearrowright}
                    alt="white arrow"
                    className="white_arrow"
                  />
                  )}
                </button>
              </div>
                  ) : (
                    <div className="cta_wrapper">
                      <button type="submit" className="about-button">
                        {loading ? "Loading.." : "Submit"}
                      </button>
                    </div>
                  )}
              </div>
              {/* {width > 992 ? ( null
              <div className="col-md-1 contact_col">
                <button type="submit" className="desk_btn">
                  {loading ? (
                    <div className="spinner-grow text-dark" role="status">
                    </div>
                  ) : (
                    <img
                    src={whitearrowright}
                    alt="white arrow"
                    className="white_arrow"
                  />
                  )}
                </button>
              // </div>
            ) : (
              <div className="cta_wrapper">
                <button type="submit" className="about-button">
                  {loading ? "Loading.." : "Submit"}
                </button>
              </div>
            )} */}
            </form>
           
          </div>
        </div>
      </section>
    </>
  );
};
export default Enquiry;
