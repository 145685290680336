import { React, useEffect, useState } from "react";
import "./faq.scss";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { faqBanner, minus, plus } from "../../source";
import { useWindowSize } from "react-use";
import Banner from "../../components/Banner/Banner";
import { faqData } from "../../Data/faqData";
import Sticky from "../../components/Sticky/Sticky";


const Faq = () => {
  const { width } = useWindowSize();
  const [clicked, setClicked] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const toggle = (i) => {
    if (clicked === i) {
      //if clicked question is already active, then close it
      return setClicked(null);
    }
    setClicked(i);
  };

  return (
    <>
      <Header />
      <section className="faq_sec1">
        <Banner
          imgSrc={width > 767 ? faqBanner : faqBanner}
          linkTwoText="Faqs"
          linkTwoDisabled
          headingText={width > 767 ? "Frequently asked questions" : "FAQs"}
        />
      </section>


      <section className="faq_sec2">
        <div className="my_container">
          <div className="faq_container">

            {faqData.map((item, i) => (
                <>
                  <div className="faq_box" id={i}>
                    <div className={`faq_wrap ${clicked == i ? "active" : ""}`} onClick={() => toggle(i)} key={i}>
                      <h4 className={`faq_title ${clicked == i ? "active" : ""}`}>{item.question}</h4>
                      <span className="faq_span">
                        {clicked === i ? (
                          <img className="minus_icon" src={minus} />
                        ) : (
                          <img className="plus_icon" src={plus} />
                        )}
                      </span>
                    </div>
                    <div className="faq_dropdown">
                      {clicked === i ? (
                        <p
                          className="faq_p"
                          dangerouslySetInnerHTML={{ __html: item.answer }}
                        ></p>
                      ) : null}
                    </div>
                  </div>
                </>
              )
            )};

          </div>
        </div>
      </section>

      {width < 767 ? (
        <Sticky />
      ) : null}

      <Footer />
    </>
  );
};

export default Faq;
