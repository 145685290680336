import React, { useEffect, useRef, useState } from "react";
import "./homepage.scss";
import Header from "../../components/Header/Header";
import {
  banner,
  business,
  cardpatch,
  handshake,
  images,
  leftarrow,
  mbbanner,
  rightarrow,
  rocketLaunch,
  supplyArrow,
  whitearrowleft,
  whitearrowright,
} from "../../source";
import Sticky from "../../components/Sticky/Sticky";
import Footer from "../../components/Footer/Footer";
import Enquiry from "../../components/Enquiry/Enquiry";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import { homepageApp, homepageProduct } from "../../Data/homepageData";
import { useWindowSize } from "react-use";
import {
  aboutusURL,
  contactusURL,
  industriesURL,
  productsURL,
} from "../../helpers/paths";
import { Link } from "react-router-dom";
import WorldMap from "../../components/WorldMap/Worldmap";
import { newsArr } from "../../Data/newsData";
import Whatsapp from "../../components/Whatsapp/Whatspp";

const Homepage = () => {
  const { width } = useWindowSize();
  // const [toggler, setToggler] = useState(false);
  // const [over, setOver] = useState(false);
  const [marginRight, setMarginRight] = useState("");
  const targetRef = useRef(null);
  // const appProductLength = homepageProduct.length;

  // const bannerImage = [
  //   {
  //     id: 1,
  //     image: images.banner1.image,
  //     alt: images.banner1.alt,
  //   },
  //   {
  //     id: 2,
  //     image: images.banner1.image,
  //     alt: images.banner1.alt,
  //   },
  // ];

  // const mbBannerImage = [
  //   {
  //     id: 1,
  //     image: images.mbbanner1.image,
  //     alt: images.mbbanner1.alt,
  //   },
  //   {
  //     id: 2,
  //     image: images.mbbanner1.image,
  //     alt: images.mbbanner1.alt,
  //   },
  // ];

  // const bannerList = bannerImage.map((item, i) => (
  //   <SwiperSlide key={i}>
  //     <img src={item.image} alt={item.alt} className="banner_img" />
  //   </SwiperSlide>
  // ));

  // const mbBannerList = mbBannerImage.map((item, i) => (
  //   <SwiperSlide key={i}>
  //     <img src={item.image} alt={item.alt} className="mbbanner_img img-fluid" />
  //   </SwiperSlide>
  // ));

  const productList = homepageProduct.map((item, i) => (
    <SwiperSlide key={i}>
      <div className="product_col" data-aos="fade-up">
        <div className="img_wrapper">
          <Link to={item.link} className="product_link">
            <img
              src={item.img}
              alt={item.alt}
              className="product_img"
              data-aos="fade-up"
            />
          </Link>
        </div>
        <div className="product_container">
          {width > 391 ? (
            <h2
              className="product_title"
              dangerouslySetInnerHTML={{ __html: item.title }}
              data-aos="fade-up"
            />
          ) : (
            <h2
              className="product_title"
              dangerouslySetInnerHTML={{ __html: item.mbtitle }}
              data-aos="fade-up"
            />
          )}
          <p className="product_desc" data-aos="fade-up">
            {item.desc}
          </p>
        </div>
        <Link to={item.link} className="product_link">
          Know more
        </Link>
      </div>
    </SwiperSlide>
  ));

  const appList = homepageApp.map((item, i) => (
    <SwiperSlide key={i}>
      <div className="app_slider" data-aos="fade-up">
        <Link to={item.link}>
          <div className="img_wrapper">
            <img
              src={item.img}
              className="app_img img-fluid"
              data-aos="fade-up"
              alt="application"
              loading="lazy"
            />
          </div>
          <div className="app_details">
            <h2 className="app_title" data-aos="fade-up">
              {" "}
              {item.title}
            </h2>
            <p className="app_desc" data-aos="fade-up">
              {item.desc}
            </p>
          </div>
        </Link>
      </div>
    </SwiperSlide>
  ));

  useEffect(() => {
    if (targetRef.current) {
      const style = window.getComputedStyle(targetRef.current);
      setMarginRight(style.marginRight);
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <section className="home_sec1" data-aos="fade">
        <div className="banner_sec">
          <div className="banner_wrapper" data-aos="fade">
            <Swiper
              slidesPerView={1}
              allowTouchMove={false}

              // navigation={{
              //   prevEl: ".banner_left_arrow",
              //   nextEl: ".banner_right_arrow",
              // }}
            >
              {width > 767 ? (
                <>
                  <SwiperSlide>
                    <img src={banner} alt="Banner" className="banner_img" />
                    <div className="main_wrapper">
                      <div className="my_container">
                        <div className="banner_content_box">
                          {/* <h1 className="banner_heading text_animation_down">
                Exporter of the{" "}
                <span className="color_text">
                  versatile <br />
                  mineral
                </span>{" "}
                that enhances <br />
                countless products
              </h1> */}
                          <h1 className="banner_heading text_animation_down">
                            {/* <span className="color_text">
                              Titanium Dioxide serves as an <br /> important{" "}
                            </span>{" "}
                            component, <br /> elevating the appeal and <br />{" "}
                            efficacy of a wide range of <br /> consumer goods. */}
                            Building A Brighter
                            <br /> Future Together
                          </h1>
                          {/* <p className="banner_sub_heading text_animation_down">
                From paper to plastics, Titanium Dioxide is a key ingredient
                that enhances{" "}
                <span className="banner_text_block">
                  {" "}
                  the appearance and performance of many everyday items.
                </span>
              </p> */}
                          <p className="banner_sub_heading text_animation_down">
                            High performance premium titanium dioxide pigments.
                          </p>
                          <div className="cta_wrapper text_animation_up">
                            <Link to="/product/r-504" className="common_cta">
                              Know more
                            </Link>
                          </div>
                        </div>
                        <div className="banner_tio2_text text_animation_down">
                          {/* <h2 className="banner_tio2">
                          TiO<sub className="banner_tio2_sub">2</sub>
                        </h2> */}
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                </>
              ) : (
                <SwiperSlide>
                  <img
                    src={mbbanner}
                    alt="Banner"
                    className="mbbanner_img img-fluid"
                  />
                  <div className="my_container">
                    <div className="banner_content_box">
                      <h1 className="banner_heading text_animation_down">
                        {/* <span className="color_text">
                          Titanium Dioxide is a key ingredient{" "}
                        </span>{" "}
                        that enhances the appearance and performance of many
                        everyday items. */}
                        Building A Brighter
                        <br /> Future Together
                      </h1>
                      <p className="banner_sub_heading text_animation_down">
                        High performance premium titanium dioxide pigments.
                      </p>
                      <div className="cta_wrapper text_animation_up">
                        <Link to="/product/r-504" className="common_cta">
                          Know more
                        </Link>
                      </div>
                    </div>
                    {/* <div className="banner_tio2_text">
                      <h2 className="banner_tio2">
                        Tio<sub className="banner_tio2_sub">2</sub>
                      </h2>
                    </div> */}
                  </div>
                </SwiperSlide>
              )}
              {/* </Swiper> */}
              {/* {width > 767 ? (
                <div className="banner_arrows_wrapper">
                  <img
                    src={whitearrowleft}
                    className="banner_left_arrow"
                    onMouseOver={(e) => (e.currentTarget.src = yellowleft)}
                    onMouseOut={(e) => (e.currentTarget.src = whitearrowleft)}
                  />
                  <img
                    src={whitearrowright}
                    className="banner_right_arrow"
                    onMouseOver={(e) => (e.currentTarget.src = yellowright)}
                    onMouseOut={(e) => (e.currentTarget.src = whitearrowright)}
                  />
                </div>
              ) : null} */}
            </Swiper>
          </div>

          {/* <div className="my_container">
            <div className="banner_content_box">
              <h1 className="banner_heading text_animation_down">
                <span className="color_text">
                  Titanium Dioxide is a key <br /> ingredient{" "}
                </span>{" "}
                that enhances <br /> the appearance and <br /> performance of
                many <br /> everyday items.
              </h1>
              <p className="banner_sub_heading text_animation_down">
                High performance premium titanium dioxide pigments.
              </p>
              <div className="cta_wrapper text_animation_up">
                <Link to={productsURL} className="common_cta">
                  Know more
                </Link>
              </div>
            </div>
            <div className="banner_tio2_text">
              <h2 className="banner_tio2">
                Tio<sub className="banner_tio2_sub">2</sub>
              </h2>
            </div>
          </div> */}
        </div>

        <div className="home_sec2" data-aos="fade-up">
          <div className="my_container">
            <div className="row quality_row" data-aos="fade-up">
              <div className="col-lg-4 quality_col_1">
                <div className="quality_col">
                  {/* <img src={quality} alt="Quality" className="quality_img"/> */}

                  {/* icon animation start */}
                  {/* <div className="socialicons">
                    <div className="socialicon">
                      <div className="dribbblecolor socialiconcircle1"></div>
                      <div className="socialiconcircle2">
                        <img src={business} className="icons fa-dribbble dribbble" />
                      </div>
                    </div>
                  </div> */}

                  <div className="icon_animation">
                    <img
                      src={business}
                      alt="Business"
                      className="animation_img"
                    />
                  </div>
                  {/* icon animation end */}

                  <h2 className="quality_heading">Quality</h2>
                  <p className="quality_desc">
                    Our products are developed to cater to global standards
                  </p>
                </div>
              </div>
              <div className="col-lg-4 quality_col_1">
                <div className="quality_col">
                  {/* <img src={innovation} alt="Quality" className="quality_img" /> */}
                  <div className="icon_animation">
                    <img
                      src={rocketLaunch}
                      alt="Business"
                      className="animation_img"
                    />
                  </div>
                  <h2 className="quality_heading">Innovation & Development</h2>
                  <p className="quality_desc">
                    Our uncompromising focus on product development & help us to
                    drive excellence all around the world
                  </p>
                </div>
              </div>
              <div className="col-lg-4 quality_col_1">
                <div className="quality_col">
                  {/* <img src={reliability} alt="Quality" className="quality_img" /> */}
                  <div className="icon_animation">
                    <img
                      src={handshake}
                      alt="Business"
                      className="animation_img"
                    />
                  </div>
                  <h2 className="quality_heading">Reliability & Assurance</h2>
                  <p className="quality_desc">
                    Stable and dependable quality for our customers
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="home_sec3" data-aos="fade-up">
        <div className="my_container">
          <div className="row about_row" data-aos="fade-up">
            <div className="col-md-6" data-aos="fade-up">
              <div className="about_col">
                {/* <p className="subheading" data-aos="fade-up">
                  WHAT WE DO
                </p> */}
                <h2 className="heading" data-aos="fade-up">
                  WHAT WE DO
                </h2>
                <p className="subheading" data-aos="fade-up">
                  About Us and Our Legacy
                </p>
              </div>
            </div>
            <div className="col-md-6" data-aos="fade-up">
              <div className="about_col text_wrapper">
                {/* <p className="text" data-aos="fade-up">
                  We at Titax are a pre-eminent supplier of Titanium Dioxide,
                  aiming to create an everlasting impact with our technical
                  expertise, research and advanced technology formulated in
                  Europe. <br />
                  Over the past few decades, we have strategically assisted our
                  customers and partners to move their business forward in this
                  competitive industry, creating a new benchmark for
                  reliability.
                </p> */}
                <p className="text" data-aos="fade-up">
                  At Titax, we're proud to supply premium quality Titanium
                  Dioxide. We use our deep technical knowledge, strong research
                  skills, and advanced technology developed in Europe to help
                  our clients succeed in this competitive environment. Over the
                  years, we've helped our customers and partners achieve great
                  success, making our products a reliable and trusted choice.
                </p>
                <div className="cta_wrapper" data-aos="fade-up">
                  <Link to={aboutusURL} className="about-button">
                    Know more
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="img_wrapper">
            {/* Fslightbox start */}
            {/* {width > 767 ? (
              <img src={video} alt="video banner" className="video_banner" />
            ) : (
              <img
                src={mbvideo}
                alt="video banner"
                className="mbvideo_banner"
              />
            )}
            <img
              src={play}
              alt="play button"
              className="play_btn"
              onClick={() => setToggler(!toggler)}
            /> */}
            {/* Fslightbox end */}

            {/* <a
              href="https://www.youtube.com/watch?v=yple9O14gwU&ab_channel=Titax"
              target="_blank"
              rel="noreferrer"
            > */}
            {/* <img src={play} alt="play button" className="play_btn" /> */}
            {/* </a> */}

            <iframe
              className="titax_youtube"
              width="100%"
              height={width > 992 ? 602 : "" || width > 600 ? 425 : 193}
              src="https://www.youtube.com/embed/yple9O14gwU"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
              style={{ border: "2px solid #E8E8E8" }}
              data-aos="fade-up"
            ></iframe>
          </div>
        </div>
      </section>

      <section className="home_sec4" data-aos="fade-up">
        <div className="my_container">
          <h2 className="heading" data-aos="fade">
            PRODUCTS
          </h2>
          <p className="subheading" data-aos="fade">
            Our Products
          </p>
          <p className="text" data-aos="fade">
            Titanium dioxide grades for Paints and Coatings, Plastic and Ink
            applications.
          </p>
          <div className="product_row" data-aos="fade-up">
            {/* {width > 834 || width < 768 ? null : ( */}
            {width > 767 ? (
              <div className="arrows_wrapper">
                <img
                  src={leftarrow}
                  className="left_arrow"
                  alt="arrow"
                  loading="lazy"
                  onMouseOver={(e) => (e.currentTarget.src = whitearrowleft)}
                  onMouseOut={(e) => (e.currentTarget.src = leftarrow)}
                />
                <img
                  src={rightarrow}
                  className="right_arrow"
                  alt="arrow"
                  loading="lazy"
                  onMouseOver={(e) => (e.currentTarget.src = whitearrowright)}
                  onMouseOut={(e) => (e.currentTarget.src = rightarrow)}
                />
              </div>
            ) : null }
            <Swiper
              className="productSwiper"
              slidesPerView={3}
              slidesPerGroup={1}
              spaceBetween={98}
              autoHeight={true}
              modules={[Navigation]}
              navigation={{
                nextEl: ".right_arrow",
                prevEl: ".left_arrow",
              }}
              breakpoints={{
                0: {
                  spaceBetween: 0,
                  slidesPerView: 1,
                },
                768: {
                  spaceBetween: 60,
                  slidesPerView: 2,
                },
                992: {
                  spaceBetween: 50,
                  slidesPerView: 3,
                },
                1280: {
                  spaceBetween: 70,
                  slidesPerView: 3,
                },
                1536: {
                  spaceBetween: 80,
                  slidesPerView: 3,
                },
                1600: {
                  spaceBetween: 98,
                  slidesPerView: 3,
                },
                1920: {
                  spaceBetween: 99,
                  slidesPerView: 3,
                },
                2250: {
                  spaceBetween: 120,
                  slidesPerView: 3,
                },
              }}
            >
              {productList}

              {width <= 767 ? (
                <div className="mbarrows_wrapper">
                  <img
                    src={leftarrow}
                    className="left_arrow"
                    alt="arrow"
                    loading="lazy"
                    onMouseOver={(e) => (e.currentTarget.src = whitearrowleft)}
                    onMouseOut={(e) => (e.currentTarget.src = leftarrow)}
                  />
                  <img
                    src={rightarrow}
                    className="right_arrow"
                    alt="arrow"
                    loading="lazy"
                    onMouseOver={(e) => (e.currentTarget.src = whitearrowright)}
                    onMouseOut={(e) => (e.currentTarget.src = rightarrow)}
                  />
                </div>
              ) : null}
              {/* <div className="cta_wrapper" data-aos="fade-up">
                <Link to={productsURL} className="about-button">
                  Know more
                </Link>
              </div> */}
            </Swiper>
          </div>
        </div>
      </section>

      <section className="home_sec5" data-aos="fade-up">
        <div className="my_container">
          <div className="row app_row" data-aos="fade-up">
            <h2 className="heading" data-aos="fade-up">
              APPLICATIONS
            </h2>
            <div className="col-md-5 app_col" data-aos="fade-up">
              <p className="subheading" data-aos="fade-up">
                Range of TiO2 application
              </p>
            </div>
            <div className="col-md-7 app_col" data-aos="fade-up">
              <p className="text" data-aos="fade-up">
                Well-Weighted Portfolio of TiO2 Grades in Sulphate and Chloride
                Processes.
              </p>
            </div>
          </div>
          <div className="app_swiper">
            <Swiper
              className="appSwiper"
              slidesPerView={3}
              slidesPerGroup={1}
              spaceBetween={60}
              loop={true}
              modules={[Navigation]}
              navigation={{
                nextEl: ".appright_arrow",
                prevEl: ".appleft_arrow",
              }}
              breakpoints={{
                0: {
                  spaceBetween: 20,
                  slidesPerView: 1,
                },
                768: {
                  spaceBetween: 50,
                  slidesPerView: 2,
                },
                992: {
                  spaceBetween: 40,
                  slidesPerView: 3,
                },
                1280: {
                  spaceBetween: 60,
                  slidesPerView: 3,
                },
                2100: {
                  spaceBetween: 90,
                  slidesPerView: 3,
                },
              }}
            >
              {appList}
            </Swiper>
          </div>
          <div className="row app_wrapper">
            <div className="col-md-6 order-md-2">
              <div className="arrows_wrapper" data-aos="fade-up">
                <img
                  // src={over ? whitearrow : leftarrow }
                  // className={`appleft_arrow ${over ? "appwhiteleft_arrow" : "appleft_arrow"}`}
                  // onMouseOver={() => setOver(true)}
                  // onMouseOut={() => setOver(false)}
                  src={leftarrow}
                  className="appleft_arrow"
                  alt="arrow"
                  loading="lazy"
                  onMouseOver={(e) => (e.currentTarget.src = whitearrowleft)}
                  onMouseOut={(e) => (e.currentTarget.src = leftarrow)}
                />
                <img
                  src={rightarrow}
                  className="appright_arrow"
                  alt="arrow"
                  loading="lazy"
                  onMouseOver={(e) => (e.currentTarget.src = whitearrowright)}
                  onMouseOut={(e) => (e.currentTarget.src = rightarrow)}
                />
              </div>
            </div>
            <div className="col-md-6 order-md-1">
              <div className="cta_wrapper" data-aos="fade-up">
                <Link to={industriesURL} className="about-button">
                  Know more
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="home_sec6" data-aos="fade-up">
        <div className="my_container">
          <div className="supplier_products">
            <div className="row">
              <div className="col-md-6 col-md-6">
                <Link to={contactusURL}>
                  <div className="highlight_card">
                    <div className="img_wrapper">
                      <img
                        src={images.goodSupplier.image}
                        alt={images.goodSupplier.alt}
                        className="card_img"
                        loading="lazy"
                      />
                      <div className="overlay"></div>
                    </div>
                    <div className="patch_wrapper">
                      <div className="row patch_row">
                        <div className="col-sm-9 col-md-9">
                          <div className="text_container">
                            <div className="title">Get in Touch</div>
                            <div className="desc"></div>
                          </div>
                        </div>
                        <div className="col-sm-3 col-md-3 text-end">
                          <img src={cardpatch} alt="patch" className="patch" />
                          <img
                            src={supplyArrow}
                            alt="arrow"
                            className="arrow"
                            loading="lazy"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-md-6 col-md-6">
                <Link to={`${productsURL}/r-504`}>
                  <div className="highlight_card">
                    <div className="img_wrapper">
                      <img
                        src={images.checkProducts.image}
                        alt={images.checkProducts.alt}
                        className="card_img"
                        loading="lazy"
                      />
                      <div className="overlay"></div>
                    </div>
                    <div className="patch_wrapper">
                      <div className="row patch_row">
                        <div className="col-sm-9 col-md-9">
                          <div className="text_container">
                            <div className="title">Our Products</div>
                            <div className="desc"></div>
                          </div>
                        </div>
                        <div className="col-sm-3 col-md-3 text-end">
                          <img src={cardpatch} alt="patch" className="patch" />
                          <img
                            src={supplyArrow}
                            alt="arrow"
                            className="arrow"
                            loading="lazy"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>

          <div className="map_container" data-aos="fade-up">
            <h2 className="heading" data-aos="fade-up">
              LOCATION
            </h2>
            <p className="subheading" data-aos="fade-up">
              Our Global Footprint
            </p>
            {/* <p className="text" data-aos="fade-up">
              As a pioneer and front-runner in TiO2 production, the company
              boasts an expansive global reach and has achieved numerous
              milestones in product development through advanced technology.
            </p> */}
            <p className="text" data-aos="fade-up">
              As a pioneer and front-runner in TiO2 production, the company has
              an expansive global reach and has achieved numerous milestones in
              product development through advanced technology.
            </p>
            <h2 className="location_text" data-aos="fade-up">
              ASIA PACIFIC | MIDDLE EAST | AFRICA | SOUTH AMERICA
            </h2>
            {/* <img src={map} alt="map image" className="map_img" /> */}

            {/* map animation start  */}
            <WorldMap />
            {/* map animation end  */}
          </div>
        </div>
      </section>

      {/* <section className="home_sec7">
        <div className="my_container" ref={targetRef}>
          <div className="row">
            <div className="col-md-4">
              <div className="text_container">
                <h2 className="heading">Media</h2>
                <p className="subheading">Latest News</p>
                <p className="desc">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et.
                </p>
                {width > 767 ? (
                  <>
                    <div className="cta_wrapper">
                      <Link to={aboutusURL} className="about-button">
                        View All
                      </Link>
                    </div>
                    <div className="arrows_wrapper" data-aos="fade-up">
                      <img
                        src={leftarrow}
                        className="newsleft_arrow"
                        alt="arrow"
                        loading="lazy"
                        onMouseOver={(e) =>
                          (e.currentTarget.src = whitearrowleft)
                        }
                        onMouseOut={(e) => (e.currentTarget.src = leftarrow)}
                      />
                      <img
                        src={rightarrow}
                        className="newsright_arrow"
                        alt="arrow"
                        loading="lazy"
                        onMouseOver={(e) =>
                          (e.currentTarget.src = whitearrowright)
                        }
                        onMouseOut={(e) => (e.currentTarget.src = rightarrow)}
                      />
                    </div>
                  </>
                ) : null}
              </div>
            </div>
            <div className="col-md-8">
              <div
                className="swiper_wrapper"
                style={{ marginRight: `-${marginRight}` }}
              >
                <Swiper
                  slidesPerView={2.5}
                  spaceBetween={50}
                  modules={[Navigation]}
                  navigation={{
                    nextEl: ".newsright_arrow",
                    prevEl: ".newsleft_arrow",
                  }}
                  breakpoints={{
                    0: {
                      slidesPerView: 1,
                    },
                    767: {
                      slidesPerView: 2.5,
                    },
                  }}
                >
                  {newsArr.map((news, i) => (
                    <SwiperSlide>
                      <Link to={news.slug}>
                        <div class="news_box">
                          <div class="img_wrapper">
                            <img
                              width={417}
                              height={383}
                              src={news.img}
                              alt={news.alt}
                              class="news_img"
                              loading="lazy"
                            />
                          </div>
                          <div className="text_container">
                            <div class="title">{news.title}</div>
                            <div class="desc">{news.desc}</div>
                            <div class="cta_wrapper">
                              <Link class="cta">Read more</Link>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </SwiperSlide>
                  ))}
                </Swiper>
                {width < 767 ? (
                  <>
                    <div className="arrows_wrapper" data-aos="fade-up">
                      <img
                        src={leftarrow}
                        className="newsleft_arrow"
                        alt="arrow"
                        loading="lazy"
                        onMouseOver={(e) =>
                          (e.currentTarget.src = whitearrowleft)
                        }
                        onMouseOut={(e) => (e.currentTarget.src = leftarrow)}
                      />
                      <img
                        src={rightarrow}
                        className="newsright_arrow"
                        alt="arrow"
                        loading="lazy"
                        onMouseOver={(e) =>
                          (e.currentTarget.src = whitearrowright)
                        }
                        onMouseOut={(e) => (e.currentTarget.src = rightarrow)}
                      />
                    </div>
                    <div className="cta_wrapper">
                      <Link to={aboutusURL} className="about-button">
                        View All
                      </Link>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <Enquiry />
      <Sticky />
      <Whatsapp />
      {/* <FsLightbox
        toggler={toggler}
        sources={[
          "https://www.youtube.com/watch?v=yple9O14gwU&ab_channel=Titax",
        ]}
      /> */}
      <Footer />
    </>
  );
};

export default Homepage;
