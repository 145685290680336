import React, { useEffect } from "react";
import "./worldmap.scss";
import { map } from "../../source";

const WorldMap = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <>
      <section id="places-map" data-aos="fade-up">
        <div class="map animate-fade-in">
          <img
            src={map}
            alt="Map of Western Union Locations"
            width="903"
            height="452"
            data-aos="fade-up"
          />
          <div class="map-pins">
            <a class="map-location-marker marker-asia-pacific"></a>
            <a class="map-location-marker marker-middle-east "></a>
            <a class="map-location-marker marker-africa"></a>
            <a class="map-location-marker marker-south-america"></a>
            <a class="map-location-marker marker-1"></a>
            <a class="map-location-marker marker-2"></a>
            <a class="map-location-marker marker-3"></a>
          </div>
        </div>
      </section>
    </>
  );
};

export default WorldMap;
