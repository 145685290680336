import { images } from "../source";

export const homepageProduct = [
    {
        id: 1,
        img: images.product1.image,
        alt: images.product1.alt,
        title: "R-504 Plastic grade",
        mbtitle: "R-504 Plastic grade",
        // title: "R-504 Plastic grade | <br /> Sulphate process",
        // mbtitle: "R-504 Plastic grade <br /> | Sulphate process",
        desc: "R-504, a rutile titanium dioxide, is meticulously manufactured using the sulphate process.",
        link : "/product/r-504"
    },
    {
        id: 2,
        img: images.product2.image,
        alt: images.product2.alt,
        title: "R-702 Paint and coating grade",
        mbtitle: "R-702 Paint and coating grade",
        // title: "R-702 Paint and coating grade | <br /> Sulphate process",
        // mbtitle: "R-702 Paint and coating grade <br /> | Sulphate process",
        desc: "R-702 is a rutile titanium dioxide made by sulphate process.",
        link : "/product/r-702"
    },
    {
        id: 3,
        img: images.product3.image,
        alt: images.product3.alt,
        title: "CR-801 Paint and coating grade",
        mbtitle: "CR-801 Paint and coating grade",
        // title: "CR-801 Paint and coating grade | <br /> Chloride process",
        // mbtitle: "CR-801 Paint and coating grade <br /> | Chloride process",
        desc: "CR-801 is a zirconia and alumina inorganic and organic surface treaded rutile TiO2 pigment from the chlorination process.",
        link : "/product/cr-801"
    },
    {
        id: 4,
        img: images.product4.image,
        alt: images.product4.alt,
        title: "A-315 Titanium dioxide fiber grade",
        mbtitle: "A-315 Titanium dioxide fiber grade",
        desc: "High-grade chemical fiber titanium dioxide, organic and inorganic processed with good dispersion in water and ethylene glycol.",
        link : "/product/a-315"
    },
    {
        id: 5,
        img: images.product5.image,
        alt: images.product5.alt,
        title: "A-900 Titanium dioxide anatase grade",
        mbtitle: "A-900 Titanium dioxide anatase grade",
        desc: "This product is of high purity. The appearance is white and a little bit blue and shows good gloss. The grains of which are soft, uniform.",
        link : "/product/a-900"
    },
    {
        id: 6,
        img: images.product6.image,
        alt: images.product6.alt,
        title: "CR-522 Titanium dioxide plastic grade",
        mbtitle: "CR-522 Titanium dioxide plastic grade",
        desc: "CR-522 is a rutile titanium dioxide pigment produced by advanced chloride process and treated by special inorganic and organic processes",
        link : "/product/cr-522"
    },
    {
        id: 7,
        img: images.product7.image,
        alt: images.product7.alt,
        title: "R-414 Titanium dioxide specialised grade",
        mbtitle: "R-414 Titanium dioxide specialised grade",
        desc: "R-414 is a rutile titanium dioxide pigment with alumina, Zirconia and organic surface treatment, with high whiteness and gloss, high scattering power, high durability and excellent dispersibility",
        link : "/product/r-414"
    },
    {
        id: 8,
        img: images.product8.image,
        alt: images.product8.alt,
        title: "R-603 Titanium dioxide plastic grade",
        mbtitle: "R-603 Titanium dioxide plastic grade",
        desc: "R-603 is an excellent rutile titanium dioxide produced by a new technical Sulphate process.",
        link : "/product/r-603"
    },
    {
        id: 9,
        img: images.product9.image,
        alt: images.product9.alt,
        title: "R-612 Titanium dioxide plastic grade",
        mbtitle: "R-612 Titanium dioxide plastic grade",
        desc: "",
        link : "/product/r-612"
    },
];

export const homepageApp = [
    {
        id: 1,
        img: images.exterior.image,
        alt: images.exterior.alt,
        title: "Coatings",
        desc: "Exterior coating",
        link : "/industries-&-applications"
    },
    {
        id: 2,
        img: images.industrialspray.image,
        alt: images.industrialspray.alt,
        title: "Coatings",
        desc: "Industrial spray coating",
        link : "/industries-&-applications"
    },
    {
        id: 3,
        img: images.woodfinish.image,
        alt: images.woodfinish.alt,
        title: "Coatings",
        desc: "Wood finishes coating",
        link : "/industries-&-applications"
    },
    {
        id: 4,
        img: images.masterbatch.image,
        alt: images.masterbatch.alt,
        title: "Plastics",
        desc: "Masterbatch",
        link : "/industries-&-applications"
    },
    {
        id: 5,
        img: images.sheets.image,
        alt: images.sheets.alt,
        title: "Plastics",
        desc: "Plastic sheets",
        link : "/industries-&-applications"
    },
    {
        id: 6,
        img: images.bottle.image,
        alt: images.bottle.alt,
        title: "Plastics",
        desc: "Plastic bottles",
        link : "/industries-&-applications"
    },
    {
        id: 7,
        img: images.gravure.image,
        alt: images.gravure.alt,
        title: "Inks",
        desc: "Gravure inks",
        link : "/industries-&-applications"
    },
    {
        id: 8,
        img: images.inkjet.image,
        alt: images.inkjet.alt,
        title: "Inks",
        desc: "Inkjet inks",
        link : "/industries-&-applications"
    },
    {
        id: 9,
        img: images.flexographic.image,
        alt: images.flexographic.alt,
        title: "Inks",
        desc: "flexographic",
        link : "/industries-&-applications"
    },
];