import { BrowserRouter, Route, Routes } from "react-router-dom";
import Routing from "./Routing";
import "react-toastify/dist/ReactToastify.css";
import MetaDecorator from "./components/MetaDecorator/MetaDecorator";
import { useEffect } from "react";
import "aos/dist/aos.css";
import { QueryClient } from "@tanstack/react-query";
import AOS from "aos";
import "aos/dist/aos.css";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});
function App() {
  useEffect(() => {
    AOS.init({
      duration: 1500,
      // disable: "mobile",
    });
    AOS.refresh();
  }, []);

  // useEffect(() => {
  //   window.history.scrollRestoration = 'manual'
  // }, []);

  return (
    <>
      <MetaDecorator
        metaTitle="Titax Titanium"
        metaDesc="Titax Titanium"
        canonicalLink={window.location}
      />
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<Routing />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
