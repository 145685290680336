import React, { useEffect, useRef, useState } from "react";
import "./table.scss";
import { fullStar, star } from "../../source";

const Table = () => {
  const [marginRight, setMarginRight] = useState("");
  const [fixedWidth, setFixedWidth] = useState(0);
  const fixedTable = useRef(null);
  const tablesContainer = useRef(null);

  useEffect(() => {
    if (fixedTable.current) {
      const style = window.getComputedStyle(fixedTable.current);
      setFixedWidth(style.width);
    }
    if (tablesContainer.current) {
      const style = window.getComputedStyle(tablesContainer.current);
      setMarginRight(style.marginRight);
    }
  }, []);

  return (
    <>
      <section className="table_sec1" data-aos="fade-up">
        <div className="my_container" ref={tablesContainer}>
          <div
            className="table_section"
            data-aos="fade-up"
            style={{ marginRight: `-${marginRight}` }}
          >
            <p className="table_title">Application Guide</p>
            <div className="both_table_wrappers">
              <table className="table_content sticky_table" ref={fixedTable}>
                <thead className="table_head">
                  <tr className="table_heading">
                    <th style={{ borderColor: "#fff" }}>
                      <p></p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="table_row1">
                    <th>
                      <p className="left_border">Application</p>
                    </th>
                  </tr>
                  <tr className="table_row2">
                    <th>
                      <p className="left_border">R-504</p>
                    </th>
                  </tr>
                  <tr className="table_row2">
                    <th>
                      <p className="left_border">R-702</p>
                    </th>
                  </tr>
                  <tr className="table_row2">
                    <th>
                      <p className="left_border">CR-801</p>
                    </th>
                  </tr>
                  <tr className="table_row2">
                    <th>
                      <p className="left_border">CR-522</p>
                    </th>
                  </tr>
                  <tr className="table_row2">
                    <th>
                      <p className="left_border">R-603</p>
                    </th>
                  </tr>
                  <tr className="table_row2">
                    <th>
                      <p className="left_border">R-612</p>
                    </th>
                  </tr>
                  <tr className="table_row2">
                    <th>
                      <p className="left_border">R-414</p>
                    </th>
                  </tr>
                  <tr className="table_row2">
                    <th>
                      <p className="left_border">A-315</p>
                    </th>
                  </tr>
                  <tr className="table_row2">
                    <th>
                      <p className="left_border">A-900</p>
                    </th>
                  </tr>
                </tbody>
              </table>
              <div className="data_table">
                <table
                  className="table_content data_table"
                  style={{ marginLeft: fixedWidth }}
                >
                  <thead className="table_head">
                    <tr className="table_heading">
                      <th className="heading" colSpan={3}>
                        <p>Coatings</p>
                      </th>
                      <th className="heading" colSpan={3}>
                        <p>Plastic</p>
                      </th>
                      <th className="heading" colSpan={2}>
                        <p>Ink</p>
                      </th>
                      <th className="heading" colSpan={3}>
                        <p>Paper</p>
                      </th>
                      <th className="heading">
                        <p>Textile</p>
                      </th>
                      <th className="heading">
                        <p>Rubber</p>
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr className="table_row1">
                      <td>
                        <p className="left_border">Industrial Coatings</p>
                      </td>
                      <td>
                        <p>Powder Coatings</p>
                      </td>
                      <td>
                        <p>Interior & Exterior Architectural</p>
                      </td>
                      <td>
                        <p>Masterbatch</p>
                      </td>
                      <td>
                        <p>ABS</p>
                      </td>
                      <td>
                        <p>PVC</p>
                      </td>
                      <td>
                        <p>Flexographic & Gravure</p>
                      </td>
                      <td>
                        <p>Reverse Laminated</p>
                      </td>
                      <td>
                        <p>Fillers</p>
                      </td>
                      <td>
                        <p>Surface Coating</p>
                      </td>
                      <td>
                        <p>Laminated</p>
                      </td>
                      <td>
                        <p>Textile</p>
                      </td>
                      <td>
                        <p>Rubber</p>
                      </td>
                    </tr>
                    <tr className="table_row2">
                      <td>
                        <p className="left_border"></p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                      <td>
                        <p>
                          <img
                            src={fullStar}
                            alt="Star"
                            className="full_star"
                          />
                        </p>
                      </td>
                      <td>
                        <p>
                          <img
                            src={fullStar}
                            alt="Star"
                            className="full_star"
                          />
                        </p>
                      </td>
                      <td>
                        <p>
                          <img
                            src={fullStar}
                            alt="Star"
                            className="full_star"
                          />
                        </p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                      <td>
                        <p>
                          {" "}
                          <img src={star} alt="Star" className="full_star" />
                        </p>
                      </td>
                    </tr>
                    <tr className="table_row2">
                      <td>
                        <p className="left_border">
                          <img
                            src={fullStar}
                            alt="Star"
                            className="full_star"
                          />
                        </p>
                      </td>
                      <td>
                        <p>
                          <img
                            src={fullStar}
                            alt="Star"
                            className="full_star"
                          />
                        </p>
                      </td>
                      <td>
                        <p>
                          <img
                            src={fullStar}
                            alt="Star"
                            className="full_star"
                          />
                        </p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                      <td>
                        <p>
                          <img src={star} alt="Star" className="star" />
                        </p>
                      </td>
                      <td>
                        <p>
                          <img src={star} alt="Star" className="star" />
                        </p>
                      </td>
                      <td>
                        <p>
                          <img src={star} alt="Star" className="star" />
                        </p>
                      </td>
                      <td>
                        <p>
                          <img src={star} alt="Star" className="star" />
                        </p>
                      </td>
                      <td>
                        <p>
                          <img src={star} alt="Star" className="star" />
                        </p>
                      </td>
                      <td>
                        <p>
                          <img src={star} alt="Star" className="star" />
                        </p>
                      </td>
                      <td>
                        <p>
                          <img
                            src={fullStar}
                            alt="Star"
                            className="full_star"
                          />
                        </p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                    </tr>
                    <tr className="table_row2">
                      <td>
                        <p className="left_border">
                          <img
                            src={fullStar}
                            alt="Star"
                            className="full_star"
                          />
                        </p>
                      </td>
                      <td>
                        <p>
                          <img
                            src={fullStar}
                            alt="Star"
                            className="full_star"
                          />
                        </p>
                      </td>
                      <td>
                        <p>
                          <img
                            src={fullStar}
                            alt="Star"
                            className="full_star"
                          />
                        </p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                      <td>
                        <p>
                        <img
                            src={star}
                            alt="Star"
                            className="full_star"
                          />
                        </p>
                      </td>
                      <td>
                        <p>
                          <img src={star} alt="Star" className="star" />
                        </p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                    </tr>
                    <tr className="table_row2">
                      <td>
                        <p className="left_border"></p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                      <td>
                        <p>
                          <img
                            src={fullStar}
                            alt="Star"
                            className="full_star"
                          />
                        </p>
                      </td>
                      <td>
                        <p>
                          <img
                            src={fullStar}
                            alt="Star"
                            className="full_star"
                          />
                        </p>
                      </td>
                      <td>
                        <p>
                          <img
                            src={fullStar}
                            alt="Star"
                            className="full_star"
                          />
                        </p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                    </tr>
                    <tr className="table_row2">
                      <td>
                        <p className="left_border"></p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                      <td>
                        <p>
                          <img
                            src={fullStar}
                            alt="Star"
                            className="full_star"
                          />
                        </p>
                      </td>
                      <td>
                        <p>
                          <img
                            src={fullStar}
                            alt="Star"
                            className="full_star"
                          />
                        </p>
                      </td>
                      <td>
                        <p>
                          <img
                            src={fullStar}
                            alt="Star"
                            className="full_star"
                          />
                        </p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                    </tr>
                    <tr className="table_row2">
                      <td>
                        <p className="left_border"></p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                      <td>
                        <p>
                          <img
                            src={fullStar}
                            alt="Star"
                            className="full_star"
                          />
                        </p>
                      </td>
                      <td>
                        <p>
                          <img
                            src={fullStar}
                            alt="Star"
                            className="full_star"
                          />
                        </p>
                      </td>
                      <td>
                        <p>
                          <img
                            src={fullStar}
                            alt="Star"
                            className="full_star"
                          />
                        </p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                    </tr>
                    <tr className="table_row2">
                      <td>
                        <p className="left_border">
                          <img
                            src={fullStar}
                            alt="Star"
                            className="full_star"
                          />
                        </p>
                      </td>
                      <td>
                        <p>
                          <img
                            src={fullStar}
                            alt="Star"
                            className="full_star"
                          />
                        </p>
                      </td>
                      <td>
                        <p>
                          <img
                            src={fullStar}
                            alt="Star"
                            className="full_star"
                          />
                        </p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                      <td>
                        <p>
                          <img src={star} alt="Star" className="star" />
                        </p>
                      </td>
                      <td>
                        <p>
                          <img src={star} alt="Star" className="star" />
                        </p>
                      </td>
                      <td>
                        <p>
                          <img src={star} alt="Star" className="star" />
                        </p>
                      </td>
                      <td>
                        <p>
                          <img src={star} alt="Star" className="star" />
                        </p>
                      </td>
                      <td>
                        <p>
                          <img src={star} alt="Star" className="star" />
                        </p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                    </tr>
                    <tr className="table_row2">
                      <td>
                        <p className="left_border"></p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                      <td>
                        <p>
                        <img
                            src={fullStar}
                            alt="Star"
                            className="full_star"
                          />
                        </p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                      <td>
                        <p>
                          <img
                            src={fullStar}
                            alt="Star"
                            className="full_star"
                          />
                        </p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                    </tr>
                    <tr className="table_row2">
                      <td>
                        <p className="left_border"><img
                            src={star}
                            alt="Star"
                            className="full_star"
                          /></p>
                      </td>
                      <td>
                        <p>
                          <img src={star} alt="Star" className="star" />
                        </p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                      <td>
                        <p>
                        <img
                            src={star}
                            alt="Star"
                            className="full_star"
                          />
                        </p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                      <td>
                        <p>
                        <img
                            src={star}
                            alt="Star"
                            className="full_star"
                          />
                        </p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                      <td>
                        <p>
                        <img
                            src={star}
                            alt="Star"
                            className="full_star"
                          />
                        </p>
                      </td>
                      <td>
                        <p>
                          <img
                            src={fullStar}
                            alt="Star"
                            className="full_star"
                          />
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            {/* <table className="data_table">
            <thead className="table_head">
              <tr className="table_heading">
                <th><p className="para"></p></th>
                <th className="heading"><p className="para">Coatings</p></th>
                <th><p className="para"></p></th>
                <th className="heading"><p className="para">Plastic</p></th>
                <th><p className="para"></p></th>
                <th className="heading"><p className="para">Ink</p></th>
                <th><p className="para"></p></th>
              </tr>
            </thead>

            <tbody className="table_body">
              <tr className="table_row1">
                <th>Application</th>
                <td>Interior Architectural</td>
                <td>Exterior Architectural</td>
                <td>Masterbatch</td>
                <td>PVC Profile</td>
                <td>High Gloss</td>
                <td>Reverse Laminated</td>
              </tr>
              <tr className="table_row2">
                <th>Titax R-504</th>
                <td></td>
                <td></td>
                <td>
                  <img src={fullStar} alt="Star" className="full_star" />
                </td>
                <td>
                  <img src={fullStar} alt="Star" className="full_star" />
                </td>
                <td></td>
                <td></td>
              </tr>
              <tr className="table_row3">
                <th>Titax R-702</th>
                <td>
                  <img src={fullStar} alt="Star" className="full_star" />
                </td>
                <td>
                  <img src={fullStar} alt="Star" className="full_star" />
                </td>
                <td></td>
                <td>
                  <img src={star} alt="Star" className="star" />
                </td>
                <td>
                  <img src={star} alt="Star" className="star" />
                </td>
                <td>
                  <img src={star} alt="Star" className="star" />
                </td>
              </tr>
              <tr className="table_row4">
                <th>Titax CR-801</th>
                <td>
                  <img src={fullStar} alt="Star" className="full_star" />
                </td>
                <td>
                  <img src={fullStar} alt="Star" className="full_star" />
                </td>
                <td></td>
                <td>
                  <img src={star} alt="Star" className="star" />
                </td>
                <td>
                  <img src={star} alt="Star" className="star" />
                </td>
                <td>
                  <img src={star} alt="Star" className="star" />
                </td>
              </tr>
            </tbody>
          </table> */}
          </div>
          <div className="table_index_wrapper" data-aos="fade-up">
            <div className="table_index">
              <div className="index_container">
                <img src={star} alt="" className="index_img1" />
                <p className="index_name">Suitable</p>
              </div>
            </div>
            <div className="table_index">
              <div className="index_container">
                <img src={fullStar} alt="" className="index_img2" />
                <p className="index_name">Highly Recommended</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Table;
