// General
export { default as titaxlogo } from "./images/General/titax_logo_new_1.png";
export { default as wright } from "./images/General/arrow_right_white.svg";
export { default as aright } from "./images/General/arrow_right_1.svg";
export { default as thankYou } from "./images/General/thank-you.png";
export { default as mbthankYou } from "./images/General/mbthank_you.png";
export { default as notFound } from "./images/General/not_found.png";
export { default as mbnotFound } from "./images/General/mbnot_found.png";
export { default as plus } from "./images/General/plus_icon.png";
export { default as minus } from "./images/General/minus_icon.png";
export { default as downArrow } from "./images/General/down_arrow.svg";
// export { default as blackArrow } from "./images/General/black_arrow.svg";
export { default as blackArrow } from "./images/General/desk_black_arrow.svg";
export { default as Whatspp } from "./images/General/Whatsapp_icon.png";

//homepage
export { default as mapImg } from "./images/homepage/map_img.png";
export { default as banner } from "./images/homepage/banner_new_3.jpg";
export { default as mbbanner } from "./images/homepage/mb_banner_new_2.jpg";
export { default as quality } from "./images/homepage/Quality.png";
export { default as innovation } from "./images/homepage/Innovation.png";
export { default as reliability } from "./images/homepage/Reliability.png";
export { default as video } from "./images/homepage/aboutvideo.png";
export { default as mbvideo } from "./images/homepage/mb_aboutvideo.png";
export { default as play } from "./images/homepage/play.png";
export { default as leftarrow } from "./images/homepage/left_arrow.svg";
export { default as rightarrow } from "./images/homepage/right_arrow.svg";
export { default as whitearrow } from "./images/homepage/white_arrow.png";
export { default as whitearrowright } from "./images/homepage/white_arrow_right.svg";
export { default as whitearrowleft } from "./images/homepage/white_arrow_left.svg";
export { default as map } from "./images/homepage/map_img.png";
export { default as business } from "./images/homepage/business_center.svg";
export { default as rocketLaunch } from "./images/homepage/rocket_launch.svg";
export { default as handshake } from "./images/homepage/handshake.svg";
export { default as circle1 } from "./images/homepage/circle_1.svg";
export { default as banner504 } from "./images/products/504.png";
export { default as banner702 } from "./images/products/702.png";
export { default as banner801 } from "./images/products/801.png";
export { default as mailpro } from "./images/products/mail-product.svg";
export { default as docpro } from "./images/products/doc-product.svg";
export { default as wStar } from "./images/homepage/white_star.png";
export { default as wWaterDrop } from "./images/homepage/white_water_drop.png";
export { default as wBadge } from "./images/homepage/white_badge.png";
export { default as wSun } from "./images/homepage/white_sun.png";
export { default as wHeat } from "./images/homepage/white_heat.png";
export { default as wLowWaterDrop } from "./images/homepage/white_low_water.png";
export { default as wDispersibility } from "./images/homepage/dispersibility.png";
export { default as wWeather } from "./images/homepage/weather_resistance.png";
export { default as wHiding } from "./images/homepage/hiding.png";
export { default as wGloss } from "./images/homepage/gloss.png";
export { default as cardpatch } from "./images/homepage/cta-patch.svg";
export { default as supplyArrow } from "./images/homepage/arrow-right-supplier.svg";

// banner
export { default as aboutusBanner } from "./images/banner/about_us_1.jpg";
export { default as mbaboutusBanner } from "./images/banner/mbabout_banner.png";
export { default as industriesBanner } from "./images/banner/Industries_Banner.jpg";
export { default as mbindustriesBanner } from "./images/banner/mbindustries_banner.png";
export { default as productBanner } from "./images/banner/Products_Banner.jpg";
export { default as mbproductBanner } from "./images/banner/mbproducts_banner.png";
export { default as faqBanner } from "./images/banner/faq_banner.png";

// about us
export { default as tio2 } from "./images/aboutus/TiO2.png";
export { default as aboutMap } from "./images/aboutus/about_map.png";
export { default as locationIcon } from "./images/aboutus/location_icon.png";
export { default as personIcon } from "./images/aboutus/person.png";
export { default as aboutExcellent } from "./images/products/excellent.png";
export { default as aboutLow } from "./images/products/low_moisture.png";
export { default as aboutStable } from "./images/products/stable.png";
export { default as aboutHigh } from "./images/products/high.png";
export { default as aboutHighHeat } from "./images/products/high_heat.png";
export { default as aboutLowMositure } from "./images/products/low_moisture.png";

// industries
export { default as yellowright } from "./images/industries/yellow_right_arrow.svg";
export { default as yellowleft } from "./images/industries/yellow_left_arrow.svg";
export { default as usp1Img } from "./images/industries/usp1_img.png";
export { default as usp2Img } from "./images/industries/usp2_img.png";
export { default as fullStar } from "./images/industries/full_star.png";
export { default as star } from "./images/industries/star.png";

// contactus
export { default as dropDownArrow } from "./images/contactus/dropDown_arrow.png";
export { default as call } from "./images/contactus/Call.svg";
export { default as email } from "./images/contactus/Email.svg";
export { default as location } from "./images/contactus/Location.svg";
export { default as contactMap } from "./images/contactus/contact_map.png";
export { default as rightLine } from "./images/contactus/right_line.png";

export let images = {
  // homepage
  banner1: {
    image: require("./images/homepage/banner.jpg"),
    alt: "Banner",
  },
  mbbanner1: {
    image: require("./images/homepage/mb_banner.png"),
    alt: "Banner",
  },
  product1: {
    image: require("./images/homepage/504.png"),
    alt: "504",
  },
  product2: {
    image: require("./images/homepage/702.png"),
    alt: "702",
  },
  product3: {
    image: require("./images/homepage/801.png"),
    alt: "801",
  },
  product4: {
    image: require("./images/homepage/A_315.png"),
    alt: "315",
  },
  product5: {
    image: require("./images/homepage/A_900.png"),
    alt: "900",
  },
  product6: {
    image: require("./images/homepage/CR_522.png"),
    alt: "522",
  },
  product7: {
    image: require("./images/homepage/R_414.png"),
    alt: "414",
  },
  product8: {
    image: require("./images/homepage/R_603.png"),
    alt: "603",
  },
  product9: {
    image: require("./images/homepage/R_612.png"),
    alt: "612",
  },
  exterior: {
    image: require("./images/homepage/Exterior_coating.png"),
    alt: "Exterior coating",
  },
  woodfinish: {
    image: require("./images/homepage/Wood_finishes_coating.png"),
    alt: "Wood finishes coating",
  },
  industrialspray: {
    image: require("./images/homepage/Industrial_spray_coating.png"),
    alt: "Industrial spray coating",
  },
  masterbatch: {
    image: require("./images/homepage/Masterbatch.png"),
    alt: "Masterbatchs",
  },
  sheets: {
    image: require("./images/homepage/Plastic_sheets.png"),
    alt: "Plastic sheets",
  },
  bottle: {
    image: require("./images/homepage/Plastic_bottles.png"),
    alt: "Plastic bottles",
  },
  gravure: {
    image: require("./images/homepage/Gravure_inks.png"),
    alt: "Gravure inks",
  },
  inkjet: {
    image: require("./images/homepage/Inkjet_inks.png"),
    alt: "Inkjet inks",
  },
  flexographic: {
    image: require("./images/homepage/Flexographic_inks.png"),
    alt: "Flexographic inks",
  },
  aboutQuality: {
    image: require("./images/aboutus/quality.png"),
    alt: "Quality",
  },
  aboutInnovation: {
    image: require("./images/aboutus/Innovation-Development.png"),
    alt: "Innovation & Development",
  },
  aboutReliability: {
    image: require("./images/aboutus/Reliability-Assurance.png"),
    alt: "Reliability & Assurance",
  },
  aboutTrust: {
    image: require("./images/aboutus/Trust.png"),
    alt: "Trust",
  },
  goodSupplier: {
    image: require("./images/homepage/good_supplier.jpg"),
    alt: "good supplier",
  },
  checkProducts: {
    image: require("./images/homepage/check_products.jpg"),
    alt: "Check Products",
  },

  // products
  excellent: {
    image: require("./images/products/excellent.png"),
    alt: "Excellent",
  },
  stable: {
    image: require("./images/products/stable.png"),
    alt: "Stable",
  },
  high: {
    image: require("./images/products/high.png"),
    alt: "High",
  },
  low: {
    image: require("./images/products/low.png"),
    alt: "Low",
  },
  highHeat: {
    image: require("./images/products/high_heat.png"),
    alt: "High Heat",
  },
  lowMoisture: {
    image: require("./images/products/low_moisture.png"),
    alt: "Low Moisture",
  },
  dispersibility: {
    image: require("./images/products/Good-Dispersibility.png"),
    alt: "Good Dispersibility",
  },
  weather: {
    image: require("./images/products/Excellent-weather-resistance.png"),
    alt: "Excellent weather resistance",
  },
  hiding: {
    image: require("./images/products/Excellent-Hiding-Power-Property.png"),
    alt: "Excellent Hiding Power Property",
  },
  Opacity: {
    image: require("./images/products/High-Opacity-Gloss.png"),
    alt: "High Opacity Gloss",
  },
  resistivity: {
    image: require("./images/products/High_electronic_resistivity.png"),
    alt: "High electronic resistivity",
  },
  lowIron: {
    image: require("./images/products/low_iron_content.png"),
    alt: "low iron content",
  },
  processingPerformance: {
    image: require("./images/products/Excellent_processing_performance.png"),
    alt: "Excellent processing performance",
  },
  dryPowder: {
    image: require("./images/products/Excellent_dry_powder_fluidity.png"),
    alt: "Excellent dry powder fluidity",
  },
  whitenessGloss: {
    image: require("./images/products/High_whiteness_and_gloss.png"),
    alt: "High whiteness and gloss",
  },
  highDurability: {
    image: require("./images/products/High_durability.png"),
    alt: "High durability",
  },
  highScattering: {
    image: require("./images/products/High_scattering_power.png"),
    alt: "High scattering power",
  },
  blueUndertone: {
    image: require("./images/products/Blue_undertone.png"),
    alt: "Blue undertone",
  },
  highAcromatic: {
    image: require("./images/products/High_Achromatic_strength.png"),
    alt: "High Achromatic strength",
  },
  fineParticle: {
    image: require("./images/products/Fine_particle_size.png"),
    alt: "Fine particle size",
  },
  R504: {
    image: require("./images/products/504.png"),
    alt: "R504",
  },
  R702: {
    image: require("./images/products/702.png"),
    alt: "R5R70204",
  },
  R801: {
    image: require("./images/products/801.png"),
    alt: "R801",
  },
  A301: {
    image: require("./images/products/A_315.png"),
    alt: "A301",
  },
  A900: {
    image: require("./images/products/A_900.png"),
    alt: "A900",
  },
  CR522: {
    image: require("./images/products/CR_522.png"),
    alt: "CR522",
  },
  R414: {
    image: require("./images/products/R_414.png"),
    alt: "R414",
  },
  R603: {
    image: require("./images/products/R_603.png"),
    alt: "R603",
  },
  R612: {
    image: require("./images/products/R_612.png"),
    alt: "R612",
  },

  // industries
  coating1: {
    image: require("./images/industries/Exterior_coating.png"),
    alt: "Exterior coating",
  },
  coating2: {
    image: require("./images/industries/Industrial_spray_coating.png"),
    alt: "Industrial spray coating",
  },
  coating3: {
    image: require("./images/industries/Wood_finishes_coating.png"),
    alt: "Wood finishes coating",
  },
  plastic1: {
    image: require("./images/industries/Masterbatch.png"),
    alt: "Masterbatch",
  },
  plastic2: {
    image: require("./images/industries/Plastic_sheets.png"),
    alt: "Plastic sheets",
  },
  plastic3: {
    image: require("./images/industries/Plastic_bottles.png"),
    alt: "Plastic bottles",
  },
  ink1: {
    image: require("./images/industries/Gravure_inks.png"),
    alt: "Gravure inks",
  },
  ink2: {
    image: require("./images/industries/Inkjet_inks.png"),
    alt: "Inkjet inks",
  },
  ink3: {
    image: require("./images/industries/Flexographic_inks.png"),
    alt: "Flexographic inks",
  },

  // Newsroom images
  news1: {
    image: require("./images/newsroom/media-thumb.jpg"),
    alt: "Thumb",
  },
  newslistingBan: {
    image: require("./images/newsroom/newsroom-banner.jpg"),
    alt: "Banner",
  },
  spinner: {
    image: require("./images/newsroom/spinner.gif"),
    alt: "Spinner",
  },
  newshero: {
    image: require("./images/newsroom/news-hero.jpg"),
    alt: "News Hero",
  },
};
