import React, { useEffect, useState } from "react";
import "./header.scss";
import {
  aboutusURL,
  contactusURL,
  homeURL,
  industriesURL,
  newsRoomURL,
  productsURL,
} from "../../helpers/paths";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useWindowSize } from "react-use";
import HeaderNavItem from "./HeaderNavItem";
import { titaxlogo } from "../../source";
const body = document.querySelector("body");

const navLinksArr = [
  {
    id: 1,
    type: "img",
    img: titaxlogo,
    mainLink: "/",
    class: "logo_img",
  },
  {
    id: 2,
    type: "text",
    mainTitle: "About Us",
    mainLink: aboutusURL,
  },
  {
    id: 3,
    type: "text",
    mainTitle: "Products",
    mainLink: "/product/r-504",
  },
  {
    id: 4,
    type: "text",
    mainTitle: "Industries & applications",
    mainLink: industriesURL,
  },
  // {
  //   id: 5,
  //   type: "text",
  //   mainTitle: "Newsroom",
  //   mainLink: newsRoomURL,
  // },
  {
    id: 6,
    type: "text",
    mainTitle: "Contact Us",
    mainLink: contactusURL,
  },
];

const mobNavLinksArr = [
  {
    id: 1,
    type: "text",
    mainTitle: "About Us",
    mainLink: aboutusURL,
  },
  {
    id: 2,
    type: "text",
    mainTitle: "Products",
    mainLink: "/product/r-504",
  },
  {
    id: 3,
    type: "text",
    mainTitle: "Industries & applications",
    mainLink: industriesURL,
  },
  // {
  //   id: 4,
  //   type: "text",
  //   mainTitle: "Newsroom",
  //   mainLink: newsRoomURL,
  // },
  {
    id: 5,
    type: "text",
    mainTitle: "Contact Us",
    mainLink: contactusURL,
  },
];

const Header = () => {
  const [navlinkIndex, setNavlinkIndex] = useState(null);
  const { width } = useWindowSize();
  const { pathname: locationPathname } = useLocation();
  const [isMenuActive, setIsMenuActive] = useState(false);
  const [isBg, setIsbg] = useState(false);
  const [navActiveIndex, setNavActiveIndex] = useState(null);

  useEffect(() => {
    body.style.overflow = "auto";
    if (locationPathname === "/") {
      setIsbg(false);
    } else {
      setIsbg(true);
    }
    setIsMenuActive(false);
  }, [locationPathname]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", () => handleScroll);
    };
  });

  const handleScroll = () => {
    if (window.location.pathname === "/")
      if (window.pageYOffset > 60) {
        setIsbg(true);
      } else {
        setIsbg(false);
      }
  };

  const scrollHandler = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const handleHamburgerClick = () => {
    setIsMenuActive(!isMenuActive);
    if (!isMenuActive) {
      // Disable scroll
      body.style.overflow = "hidden";
    } else {
      // Enable scroll
      body.style.overflow = "auto";
    }
  };

  const handleNavIndex = (i) => {
    if (navActiveIndex === i) {
      setNavActiveIndex(null);
    } else {
      setNavActiveIndex(i);
    }
  };

  const productRegex = /product\/[0-9 | a-z]/;
  console.log({ regexTest: productRegex.test(window.location.pathname) });
  const navlinkList = navLinksArr.map((nav, i) => (
    <li key={navLinksArr[i].id}>
      {nav.type === "text" ? (
        // <div
        //   className={`nav_tab ${navlinkIndex === i ? "active" : ""} `}
        // >
        <NavLink
          // className={nav.class}
          className={`nav_tab ${navlinkIndex === i ? "active" : ""}  ${
            productRegex.test(window.location.pathname) && nav.id == 3
              ? "active"
              : ""
          }  `}
          to={nav.mainLink}
          onClick={() => setNavlinkIndex(i)}
        >
          {nav.mainTitle}
        </NavLink>
      ) : (
        // </div>
        <div
          onClick={() => setNavlinkIndex(i)}
          className={`logo_wrapper ${navlinkIndex === i ? "" : ""} `}
        >
          <Link to={homeURL}>
            <img
              className={nav.class}
              // src={titaxlogo}
              src={nav.img}
              alt="Titax Logo"
              onClick={scrollHandler}
            />
          </Link>
        </div>
      )}
    </li>
  ));

  return (
    <>
      {/* <header className={`header_sec ${isBg ? "active" : null}`}> */}
      <header className="header_sec">
        {width > 767 ? (
          <>
            <div className="my_container">
              <div className="navlinks">
                <ul>{navlinkList}</ul>
              </div>
            </div>
          </>
        ) : (
          <header className="header">
            <div className="my_container">
              <div className="logo_wrapper">
                <Link className="logo_link" to="/">
                  <img
                    src={titaxlogo}
                    alt="titax logo"
                    className="titax_logo img-fluid"
                  />
                </Link>
              </div>
            </div>
            <nav className="nav_links_wrapper">
              <div
                className={`nav_line2_wrapper_flex_wrapper ${
                  isMenuActive ? "active" : ""
                }`}
              >
                <div className="logo_wrapper">
                  <Link className="logo_link" to="/">
                    <img
                      src={`${isMenuActive ? titaxlogo : titaxlogo}`}
                      alt="titax logo"
                      className="titax_logo_1 img-fluid"
                    />
                  </Link>
                </div>
                <ul className="nav_line2_wrapper_flex">
                  {mobNavLinksArr.map((navData, i) => (
                    <HeaderNavItem
                      navData={navData}
                      key={parseInt(navData.id)}
                      arrIndex={i}
                      handleNavIndex={handleNavIndex}
                      navActiveIndex={navActiveIndex}
                    />
                  ))}
                </ul>
              </div>
            </nav>

            {/* mobile UI start */}
            <div
              className={`hamburger_lines ${
                isMenuActive ? "active" : "" || isBg ? "black" : ""
              }`}
              onClick={handleHamburgerClick}
            >
              <div className="line line1"></div>
              <div className="line line2"></div>
              <div className="line line3"></div>
            </div>
          </header>
        )}
      </header>
    </>
  );
};

export default Header;
