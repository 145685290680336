import { React, useEffect } from "react";
import "./privacypolicy.scss";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { faqBanner } from "../../source";
import { useWindowSize } from "react-use";
import Banner from "../../components/Banner/Banner";
import Sticky from "../../components/Sticky/Sticky";
import Whatsapp from "../../components/Whatsapp/Whatspp";

const Privacypolicy = () => {
  const { width } = useWindowSize();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <section className="terms_sec1" data-aos="fade">
        <Banner
          imgSrc={width > 767 ? faqBanner : faqBanner}
          linkTwoText="Privacy Policy"
          linkTwoDisabled
          headingText="Privacy Policy"
          textColor="#1B1B1B"
        />
      </section>

      <section className="privacy_sec2" data-aos="fade-up">
        <div className="my_container">
          <p className="privacy_content" data-aos="fade-up">
            Our Privacy Policy explains how we collect, use, and disclose your
            information when you visit our website or interact with us. We
            strive to comply with applicable data protection laws and ensure
            that your personal information is handled securely. By using our
            website, you consent to the practices described in this Privacy
            Policy.
          </p>
          <div className="content_wrapper">
            <h2 className="privacy_heading privacy_heading1" data-aos="fade-up">
              Information We Collect
            </h2>
            <p className="privacy_content" data-aos="fade-up">
              When you visit our website or interact with us, we may collect
              certain information from you, including:
              <ul className="privacy_list" data-aos="fade-up">
                <li>
                  Contact information: such as your name, email address, phone
                  number, and company details when you fill out our contact form
                  or request information.
                </li>
                <li>
                  Website usage information: including your IP address, browser
                  type, operating system, and browsing activity on our website.
                </li>
                <li>
                  Communications: any correspondence, inquiries, or feedback you
                  provide to us via email or other channels.
                </li>
              </ul>
            </p>
          </div>
          <div className="content_wrapper">
            <h2 className="privacy_heading" data-aos="fade-up">How We Use Your Information</h2>
            <p className="privacy_content" data-aos="fade-up">
              We may use the information we collect from you for the following
              purposes:
              <ul className="privacy_list" data-aos="fade-up">
                <li>
                  To provide and improve our products and services: including
                  processing your requests, answering inquiries, and fulfilling
                  orders.
                </li>
                <li>
                  To personalize your experience: by understanding your
                  preferences and tailoring our offerings to meet your needs.
                </li>
                <li>
                  To communicate with you: sending important updates,
                  newsletters, marketing materials, and promotional offers that
                  may be of interest to you.
                </li>
                <li>
                  To analyze and improve our website: by monitoring website
                  usage and trends, troubleshooting issues, and optimizing our
                  website's performance.
                </li>
                <li>
                  To protect our rights and comply with legal obligations:
                  including preventing fraud, enforcing our terms of use, and
                  responding to legal requests.
                </li>
              </ul>
            </p>
          </div>
          <div className="content_wrapper">
            <h2 className="privacy_heading" data-aos="fade-up">
              Information Sharing and Disclosure
            </h2>
            <p className="privacy_content" data-aos="fade-up">
              We may share your personal information in the following
              circumstances:
              <ul className="privacy_list" data-aos="fade-up">
                <li>
                  Service Providers: We may engage trusted third-party service
                  providers to assist us in operating our website and delivering
                  our services. These service providers have access to your
                  information only to perform specific tasks on our behalf and
                  are obligated to keep it confidential.
                </li>
                <li>
                  Business Transfers: In the event of a merger, acquisition, or
                  sale of our business or assets, your personal information may
                  be transferred as part of the transaction. We will notify you
                  of any such change in ownership or control of your personal
                  information.
                </li>
                <li>
                  Legal Requirements: We may disclose your information if
                  required to do so by law or in response to valid legal
                  requests, such as court orders or government regulations.
                </li>
              </ul>
            </p>
          </div>
          <div className="content_wrapper">
            <h2 className="privacy_heading" data-aos="fade-up">Data Security</h2>
            <p className="privacy_content" data-aos="fade-up">
              We employ industry-standard security measures to protect your
              personal information from unauthorized access, use, or disclosure.
              However, no data transmission or storage system can be guaranteed
              to be 100% secure. While we strive to protect your personal
              information, we cannot warrant its absolute security.
            </p>
          </div>
          <div className="content_wrapper">
            <h2 className="privacy_heading" data-aos="fade-up">Your Rights and Choices</h2>
            <p className="privacy_content" data-aos="fade-up">
              You have certain rights regarding your personal information and
              can exercise them by contacting us. These rights include:
              <ul className="privacy_list" data-aos="fade-up">
                <li>
                  Access: You have the right to request access to the personal
                  information we hold about you.
                </li>
                <li>
                  Correction: You can request the correction of any inaccurate
                  or incomplete personal information we have about you.
                </li>
                <li>
                  Opt-out: You can opt-out of receiving promotional emails from
                  us by following the unsubscribe instructions provided in our
                  communications.
                </li>
                <li>
                  Data Deletion: You may request the deletion of your personal
                  information, subject to applicable legal and contractual
                  obligations.
                </li>
              </ul>
            </p>
          </div>
          <div className="content_wrapper">
            <h2 className="privacy_heading" data-aos="fade-up">Third-Party Links</h2>
            <p className="privacy_content" data-aos="fade-up">
              Our website may contain links to third-party websites. Please note
              that we are not responsible for the privacy practices or the
              content of those websites. We encourage you to review the privacy
              policies of those third parties before providing any personal
              information.
            </p>
          </div>
          <div className="content_wrapper">
            <h2 className="privacy_heading" data-aos="fade-up">Updates to this Privacy Policy</h2>
            <p className="privacy_content" data-aos="fade-up">
              We may update this Privacy Policy from time to time to reflect
              changes in our practices or legal requirements. We will notify you
              of any material changes by Titax.
            </p>
          </div>
        </div>
      </section>

      {width < 767 ? <Sticky /> : null}
      <Whatsapp />
      <Footer />
    </>
  );
};

export default Privacypolicy;
