import React from "react";
import "./notfound.scss";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { mbnotFound, notFound } from "../../source";
import { useWindowSize } from "react-use";
import { homeURL } from "../../helpers/paths";
import { useEffect } from "react";

const Notfound = () => {
  const { width } = useWindowSize();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <section className="not_found_sec1">
        <div className="row not_found_row">
          <div className="col-md-7 not_found_col">
            {width > 767 ? (
              <img src={notFound} alt="Thank YOu" className="not_found_img" />
            ) : (
              <img src={mbnotFound} alt="Thank YOu" className="mbnot_found_img" />
            )}
          </div>
          <div className="col-md-5 not_found_col">
            <div className="not_found_text_wrapper">
              <h2 className="not_found_heading">404</h2>
              <p className="not_found_text">
                THE PAGE YOU WERE LOOKING FOR DOES NOT EXIST
              </p>
              <div className="cta_wrapper">
                <a href={homeURL} className="common_cta">
                  Return to the website
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};
export default Notfound;
