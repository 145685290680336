import React, { useEffect } from "react";
import "./newsinner.scss";
import Header from "../../components/Header/Header";
import Sticky from "../../components/Sticky/Sticky";
import Footer from "../../components/Footer/Footer";
import Enquiry from "../../components/Enquiry/Enquiry";
import { homeURL, newsRoomURL } from "../../helpers/paths";
import { Link } from "react-router-dom";
import { newsArr } from "../../Data/newsData";
import {
  images,
  leftarrow,
  rightarrow,
  whitearrowleft,
  whitearrowright,
} from "../../source";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";

const NewsInner = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <section className="inner_sec1" data-aos="fade">
        <div className="my_container">
          <div className="text_container">
            <div className="breadcrumb">
              <Link to={homeURL}>Home&nbsp;</Link>|
              <Link to={newsRoomURL}>&nbsp;Newsroom</Link>
            </div>
            <div className="news_body">
              <h1 className="subheading" data-aos="fade-up">
                {newsArr[0].title}
              </h1>
              <div
                className="desc_wrap"
                dangerouslySetInnerHTML={{ __html: newsArr[0].desc1 }}
                data-aos="fade-up"
              />
              <img
                src={images.newshero.image}
                alt="{images.newshero.alt}"
                className="hero_img"
                loading="lazy"
                data-aos="fade-up"
              />
              <div
                className="desc_wrap"
                dangerouslySetInnerHTML={{ __html: newsArr[0].desc2 }}
                data-aos="fade-up"
              />
            </div>
          </div>
        </div>
      </section>

      <div className="inner_sec2" data-aos="fade">
        <div className="my_container">
          <div className="head_arrow_wrap">
            <h2 className="subheading" data-aos="fade-up">
              Gallery
            </h2>
            <div className="arrows_wrapper">
              <img
                src={leftarrow}
                alt="arrow"
                loading="lazy"
                className="left_arrow"
                onMouseOver={(e) => (e.currentTarget.src = whitearrowleft)}
                onMouseOut={(e) => (e.currentTarget.src = leftarrow)}
              />
              <img
                src={rightarrow}
                className="right_arrow"
                alt="arrow"
                loading="lazy"
                onMouseOver={(e) => (e.currentTarget.src = whitearrowright)}
                onMouseOut={(e) => (e.currentTarget.src = rightarrow)}
              />
            </div>
          </div>
          <div className="gallery_row" data-aos="fade-up">
            <Swiper
              slidesPerView={3}
              modules={[Navigation]}
              navigation={{
                nextEl: ".right_arrow",
                prevEl: ".left_arrow",
              }}
              spaceBetween={50}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                500: {
                  slidesPerView: 2,
                },
                992: {
                  slidesPerView: 3,
                },
              }}
            >
              {newsArr.map((img, i) => (
                <SwiperSlide key={i}>
                  <div class="img_wrapper">
                    <img src={img.img} alt={img.alt} className="gallery_img" />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
      <Enquiry />
      <Sticky />
      <Footer />
    </>
  );
};

export default NewsInner;
