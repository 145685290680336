import React, { useRef } from "react";
import "../../common.scss";
import { contactusURL } from "../../helpers/paths";
import { Link } from "react-router-dom";

export default function Sticky() {
 
  return (
    <>
      <div className="sticky_wrapper">
        <Link to={contactusURL} className="sticky_text">Get in touch</Link>
      </div>
    </>
  );
}
