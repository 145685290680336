import React, { useEffect, useRef, useState } from "react";
import "./products.scss";
import Header from "../../components/Header/Header";
import Sticky from "../../components/Sticky/Sticky";
import Footer from "../../components/Footer/Footer";
import { useWindowSize } from "react-use";
import Banner from "../../components/Banner/Banner";
import Table from "../../components/Table/Table";
import {
  docpro,
  leftarrow,
  mailpro,
  mbproductBanner,
  productBanner,
  rightarrow,
  whitearrowleft,
  whitearrowright,
} from "../../source";
// import { Swiper, SwiperSlide } from "swiper/react";
// import { Navigation } from "swiper";
import Enquiry from "../../components/Enquiry/Enquiry";
import {
  productDataList,
  findProductBySlug,
  findPrevProductSlug,
  findNextProductSlug,
} from "../../Data/productData";
import { contactusURL } from "../../helpers/paths";
import { Link, useParams, useNavigate } from "react-router-dom";
import TableOld from "../../components/TableOld/TableOld";
import Whatsapp from "../../components/Whatsapp/Whatspp";

const Products = () => {
  let { slug } = useParams();
  const { width } = useWindowSize();
  const [rightList, setRightList] = useState(0);
  const [data, setData] = useState({});
  const tabRef = useRef();
  let navigate = useNavigate();
  const [boxHeight, setBoxHeight] = useState(0);
  const boxRef = useRef(null);

  useEffect(() => {
    if (boxRef.current) {
      const style = window.getComputedStyle(boxRef.current);
      setBoxHeight(style.height);
    }
  }, []);

  useEffect(() => {
    const productData = findProductBySlug(slug);
    console.log({ productData });


   



    if (!productData) {
      // navigate("/404");
    } else {
      setData(productData);
    }
  }, [slug]);
  useEffect(() => {

    if(width > 767){
      productDataList.map((item,i)=>{
        if (item.slug == slug  && i > 2) {
          tabRef.current.scrollLeft += (i+1)*300;
        }
      })
    }

    
  }, []);

  // const tabsArr = [
  //   {
  //     id: 1,
  //     heading: "R-504 <br /> Plastic grade | <br /> Sulphate process",
  //   },
  //   {
  //     id: 2,
  //     heading: "R-702 <br /> Paint and coating grade | <br /> Sulphate",
  //   },
  //   {
  //     id: 3,
  //     heading: "CR-801 <br /> Paint and coating grade | <br /> chloride",
  //   },
  // ];

  // const mbTabsArr = [
  //   {
  //     id: 1,
  //     heading: "R-504 Plastic grade | Sulphate process",
  //   },
  //   {
  //     id: 2,
  //     heading: "R-702 Paint and coating grade | Sulphate",
  //   },
  //   {
  //     id: 3,
  //     heading: "CR-801 Paint and coating grade | chloride",
  //   },
  // ];

  const tabList = productDataList?.map((item, i) => {

    
    return (
      <div
        className="product_slider_col left_section"
        key={i}
        data-aos="fade-up"
      >
        <p
          className={`lefttab ${item.slug === slug ? "active" : null}`}
          onClick={() => navigate(`/product/${item.slug}`)}
          dangerouslySetInnerHTML={{ __html: item.tabHeading }}
          // data-aos="fade-up"
        >
          {/* <span
            dangerouslySetInnerHTML={{ __html: item.heading }}
            onClick={() => setRightList(i)}
          ></span> */}
        </p>
      </div>
    );
  });

  const leftClickHandler = (scrollOffset, i) => {
    tabRef.current.scrollLeft += scrollOffset;
    // if (i !== 0) {
    //   setRightList(i - 1);
    // } else {
    //   setRightList(i);
    // }

    const prevSlug = findPrevProductSlug(slug);
    navigate(`/product/${prevSlug}`);
  };

  const rightClickHandler = (scrollOffset, i) => {
    tabRef.current.scrollLeft += scrollOffset;
    // if (i !== awardsData?.data?.data?.length - 1) {
    // if (i !== productDataList.length - 1) {
    //   setRightList(i + 1);
    // } else {
    //   setRightList(i);
    // }

    const nextSlug = findNextProductSlug(slug);
    navigate(`/product/${nextSlug}`);
  };

  const mbTabsList = productDataList.map((item, i) => (
    <option
      value={item.slug}
      key={i}
      data-productindexid={i}
      dangerouslySetInnerHTML={{ __html: item.tabHeading }}
    >
      {/* {item.tabHeading} */}
    </option>
  ));

  const handleApplicationTabChange = (e) => {
    // const productIndexId = e.target.selectedOptions[0].dataset.productindexid;
    // setRightList(Number(productIndexId));

    navigate(`/product/${e.target.value}`);
  };

  // const tabList = tabsArr.map((item, i) => (
  //   <SwiperSlide key={i}>
  //     <div className="left_section">
  //       <p
  //         className={`lefttab ${
  //           rightList === i ? "active" : null
  //         }`}
  //       >
  //         <span
  //           dangerouslySetInnerHTML={{ __html: item.heading }}
  //           onClick={() => setRightList(i)}
  //         ></span>
  //       </p>
  //     </div>
  //   </SwiperSlide>
  // ));

  if (!data) {
    return "";
  }

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);

  return (
    <>
      <Header />

      <section className="product_sec1" data-aos="fade">
        <Banner
          imgSrc={width > 767 ? productBanner : mbproductBanner}
          linkTwoText="Products"
          linkTwoDisabled
          headingText="Products"
        />
      </section>

      <section className="product_sec2" data-aos="fade-up">
        <div className="my_container">
          <h2 className="subheading" data-aos="fade-up">
            Explore and learn about our range of products down below:
          </h2>
        </div>
      </section>

      <section className="product_sec3" data-aos="fade-up">
        <div className="my_container">
          {width > 767 ? (
            <div className="product_slider_row" data-aos="fade-up">
              <div ref={tabRef} className="product_list">
                {tabList}
              </div>

              <div className="product_arrow">
                <img
                  src={leftarrow}
                  alt="left arrow"
                  className="product_left_arrow"
                  onMouseOver={(e) => (e.currentTarget.src = whitearrowleft)}
                  onMouseOut={(e) => (e.currentTarget.src = leftarrow)}
                  onClick={() => leftClickHandler(-300, rightList)}
                />
                <img
                  src={rightarrow}
                  alt="right arrow"
                  className="product_right_arrow"
                  onMouseOver={(e) => (e.currentTarget.src = whitearrowright)}
                  onMouseOut={(e) => (e.currentTarget.src = rightarrow)}
                  onClick={() => rightClickHandler(+300, rightList)}
                />
              </div>
            </div>
          ) : (
            <div className="mbdetails_tab">
              <select
                value={slug}
                className="mb_details_tab"
                onChange={handleApplicationTabChange}
              >
                {/* {mbTabsArr.map((item, i) => (
                  <option
                    value={item.heading}
                    key={item.id}
                    onClick={() => setRightList(i)}
                    dangerouslySetInnerHTML={{ __html: item.heading }}
                  ></option>
                ))} */}
                {mbTabsList}
              </select>
            </div>
          )}

          {/* {productDataList.map((item, i) => ( */}
          <div className="row details_row" data-aos="fade-up">
            <>
              <div className="col-md-6 details_col" data-aos="fade-up">
                <h2 className="details_heading" data-aos="fade-up">
                  {data["heading"]}
                </h2>
                <p className="details_subheading" data-aos="fade-up">
                  {data["subheading"]}
                </p>

                {data["details"] !== "" ? (
                  <div className="recommended">
                    <p className="recommended_heading" data-aos="fade-up">
                      {data["title"]}
                    </p>

                    <ul
                      className="recommended_list"
                      data-aos="fade-up"
                      dangerouslySetInnerHTML={{
                        __html: data["details"],
                      }}
                    ></ul>
                  </div>
                ) : null}

                <div className="row keyfeatures_row" data-aos="fade-up">
                  <p className="keyfeatures" data-aos="fade-up">
                    {data["feature"]}
                  </p>
                  {/* {productDataList[0].keyfeature.map((item, i) => ( */}
                  {data["keyfeature"]?.map((item, i) => (
                    <div className="col-md-4 col-6 keyfeatures_col" key={i}>
                      <img
                        src={item.img}
                        alt="excellent"
                        className="keyfeature_img"
                        data-aos="fade-up"
                      />
                      <p className="keyfeature_desc" data-aos="fade-up">
                        {item.text}
                      </p>
                    </div>
                  ))}
                  {/* ))} */}
                  {/* {keyFeatureList} */}
                </div>
              </div>

              <div className="col-md-6 details_col" data-aos="fade-up">
                <div className="img_wrapper">
                  <img
                    src={data["img"]}
                    alt="R-504"
                    className="product_img"
                    data-aos="fade-up"
                  />
                </div>
              </div>

              <div className="col-md-6 details_col" data-aos="fade-up">
                <div className="description">
                  <p className="dec_heading" data-aos="fade-up">
                    {data["description"]}
                  </p>
                  <p className="text">{data["descText"]}</p>
                  {width > 767 ? (
                    <div className="cta_wrapper" data-aos="fade-up">
                      <Link to={contactusURL} className="about-button">
                        Enquire Now
                      </Link>
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="col-md-6 details_col" data-aos="fade-up">
                <div className="table_wrapper" data-aos="fade-up">
                  <p className="table_title" data-aos="fade-up">
                    TYPICAL PROPERTIES
                  </p>
                  <table className="product_table">
                    <thead>
                      <tr className="table_heading">
                        <th className="first_col">
                          <p className="first_col1">PARAMETER</p>
                        </th>
                        <th className="second_col">
                          <p className="margin_left second_col1">VALUE</p>
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {/* {productDataList[0].table.map((item, i) => ( */}
                      {data["table"]?.map((item, i) => (
                        <tr className="table_data" key={i}>
                          <td className="first_col1">
                            <p
                              className="first_col1"
                              dangerouslySetInnerHTML={{
                                __html: item.parameter,
                              }}
                            ></p>
                          </td>
                          <td>
                            <p className="margin_left second_col1">
                              {item.value}
                            </p>
                          </td>
                        </tr>
                      ))}
                      {/* ))} */}
                    </tbody>
                  </table>
                </div>

                {/* old table start */}
                {/* <div className="table_wrapper">
                <p className="table_title">TYPICAL PROPERTIES</p>

                <div className="row product_table_row">
                  <div className="col-md-8 first_col table_heading">
                    PARAMETER
                  </div>
                  <div className="col-md-4 second_col table_heading">VALUE</div>

                  <div className="col-md-8 first_col">Tio2 Content (%)</div>
                  <div className="col-md-4 second_col">{">"}93.0</div>

                  <div className="col-md-8 first_col">Brightness (%)</div>
                  <div className="col-md-4 second_col">{">"}95.1</div>

                  <div className="col-md-8 first_col">Tinting Strength</div>
                  <div className="col-md-4 second_col">{">"}1980</div>

                  <div className="col-md-8 first_col">pH value</div>
                  <div className="col-md-4 second_col">6.0~8.5</div>

                  <div className="col-md-8 first_col">
                    Oil absorption (g/100g)
                  </div>
                  <div className="col-md-4 second_col">{"<"}18</div>

                  <div className="col-md-8 first_col">
                    325 mesh sieve residue (%)
                  </div>
                  <div className="col-md-4 second_col">{"<"}0.01</div>

                  <div className="col-md-8 first_col">
                    Resistivity (ohm-meter)
                  </div>
                  <div className="col-md-4 second_col">{">"}80</div>

                  <div className="col-md-8 first_col">Oil dispersion (H)</div>
                  <div className="col-md-4 second_col">{">"}6.0</div>

                  <div className="col-md-8 first_col">
                    Volatile rate at 105°C
                  </div>
                  <div className="col-md-4 second_col">{"<"}0.5</div>

                  <div className="col-md-8 first_col">Inorganic coatingg</div>
                  <div className="col-md-4 second_col">Zr AI</div>

                  <div className="col-md-8 first_col">Organic treatment</div>
                  <div className="col-md-4 second_col">Yes</div>
                </div>
              </div> */}
                {/* old table end */}
              </div>
            </>
          </div>
          {/* ))} */}

          {/* test start */}
          {/* {productDataList.map((item, i) => ( 
            <div className="row details_row">
              <>
                <div className="col-md-6 details_col" key={i}>
                  <h2 className="details_heading">{item.heading}</h2>
                  <p className="details_subheading">{item.subheading}</p>
                  <div className="recommended">
                    <p className="recommended_heading">{item.title}</p>

                    <ul
                      className="recommended_list"
                      key={i}
                      dangerouslySetInnerHTML={{ __html: item.details }}
                    ></ul>
                  </div>

                  <div className="row keyfeatures_row">
                    <p className="keyfeatures">{item.feature}</p>
                    {productDataList[0].keyfeature.map((item, i) => (
                      <div className="col-md-4 col-6 keyfeatures_col" key={i}>
                        <img
                          src={item.img}
                          alt="excellent"
                          className="keyfeature_img"
                        />
                        <p className="keyfeature_desc">{item.text}</p>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="col-md-6 details_col">
                  <div className="img_wrapper">
                    <img src={item.img} alt="R-504" className="product_img" />
                  </div>
                </div>

                <div className="col-md-6 details_col">
                  <div className="description">
                    <p className="dec_heading">{item.description}</p>
                    <p className="text">{item.descText}</p>
                    {width > 767 ? (
                      <div className="cta_wrapper">
                        <a href="javascript:void(0)" className="about-button">
                          Know more
                        </a>
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="col-md-6 details_col">
                  <div className="table_wrapper">
                    <p className="table_title">TYPICAL PROPERTIES</p>
                    <table className="product_table">
                      <thead>
                        <tr className="table_heading">
                          <th>
                            <p>PARAMETER</p>
                          </th>
                          <th>
                            <p className="margin_left">VALUE</p>
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {productDataList[0].table.map((item, i) => (
                          <tr className="table_data" key={i}>
                            <td>
                              <p>{item.parameter}</p>
                            </td>
                            <td>
                              <p className="margin_left">{item.value}</p>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                
                </div>
              </>
            </div>
            ))} */}
          {/* test end */}

          {/* <div className="row details_row">
            <div className="col-md-6 details_col">
              <h2 className="details_heading">R-702</h2>
              <p className="details_subheading">
                Plastic grade (Sulphate process)
              </p>
              <div className="recommended">
                <p className="recommended_heading">
                  Recommended applications :
                </p>
                <ul className="recommended_list">
                  <li>• Polyolefin • PVC • ABS • Masterbatch and compounds </li>
                  <li>• Plastics in high thermal stability</li>
                </ul>
              </div>

              <div className="row keyfeatures_row">
                <p className="keyfeatures">Key features :</p>
                <div className="col-md-4 col-6 keyfeatures_col">
                  <img
                    src={excellent}
                    alt="excellent"
                    className="keyfeature_img"
                  />
                  <p className="keyfeature_desc">
                    Excellent levelling property
                  </p>
                </div>
                <div className="col-md-4 col-6 keyfeatures_col">
                  <img src={low} alt="excellent" className="keyfeature_img" />
                  <p className="keyfeature_desc">Low oil absorption</p>
                </div>
                <div className="col-md-4 col-6 keyfeatures_col">
                  <img
                    src={stable}
                    alt="excellent"
                    className="keyfeature_img"
                  />
                  <p className="keyfeature_desc">Stabilised quality</p>
                </div>
                <div className="col-md-4 col-6 keyfeatures_col">
                  <img src={high} alt="excellent" className="keyfeature_img" />
                  <p className="keyfeature_desc">High opacity and whiteness</p>
                </div>
                <div className="col-md-4 col-6 keyfeatures_col">
                  <img
                    src={highHeat}
                    alt="excellent"
                    className="keyfeature_img"
                  />
                  <p className="keyfeature_desc">High heat stability</p>
                </div>
                <div className="col-md-4 col-6 keyfeatures_col">
                  <img
                    src={lowMoisture}
                    alt="excellent"
                    className="keyfeature_img"
                  />
                  <p className="keyfeature_desc">Low moisture absorption</p>
                </div>
              </div>
            </div>

            <div className="col-md-6 details_col">
              <div className="img_wrapper">
                <img src={R504} alt="R-702" className="product_img" />
              </div>
            </div>

            <div className="col-md-6 details_col">
              <div className="description">
                <p className="dec_heading">Description :</p>
                <p className="text">
                  R-702 is a rutile titanium dioxide made by sulphate process.
                  It has an unparalleled hiding power due to the unique
                  hydrolysis and calcination process. It has a uniform particle
                  size distribution and excellent properties in gloss and
                  opacity. A special surface treatment grants R-702 with better
                  weather resistance, lower oil absorption and better levelling
                  property.
                </p>
                {width > 767 ? (
                  <div className="cta_wrapper">
                    <a href="javascript:void(0)" className="about-button">
                      Know more
                    </a>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="col-md-6 details_col">
              <div className="table_wrapper">
                <p className="table_title">TYPICAL PROPERTIES</p>
                <table className="product_table">
                  <thead>
                    <tr className="table_heading">
                      <th>
                        <p>PARAMETER</p>
                      </th>
                      <th>
                        <p className="margin_left">VALUE</p>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="table_data">
                      <td>
                        <p> TiO2 Content (%)</p>
                      </td>
                      <td>
                        <p className="margin_left">{">"}93.0</p>
                      </td>
                    </tr>
                    <tr className="table_data">
                      <td>
                        <p> Brightness (%)</p>
                      </td>
                      <td>
                        <p className="margin_left">{">"}95.1</p>
                      </td>
                    </tr>
                    <tr className="table_data">
                      <td>
                        <p> Tinting Strength</p>
                      </td>
                      <td>
                        <p className="margin_left">{">"}1980</p>
                      </td>
                    </tr>
                    <tr className="table_data">
                      <td>
                        <p> pH value</p>
                      </td>
                      <td>
                        <p className="margin_left">6.0~8.5</p>
                      </td>
                    </tr>
                    <tr className="table_data">
                      <td>
                        <p> Oil absorption (g/100g)</p>
                      </td>
                      <td>
                        <p className="margin_left">{"<"}18</p>
                      </td>
                    </tr>
                    <tr className="table_data">
                      <td>
                        <p> 325 mesh sieve residue (%)</p>
                      </td>
                      <td>
                        <p className="margin_left">{"<"}0.01</p>
                      </td>
                    </tr>
                    <tr className="table_data">
                      <td>
                        <p> Resistivity (ohm-meter)</p>
                      </td>
                      <td>
                        <p className="margin_left">{">"}80</p>
                      </td>
                    </tr>
                    <tr className="table_data">
                      <td>
                        <p> Oil dispersion (H)</p>
                      </td>
                      <td>
                        <p className="margin_left">{">"}6.0</p>
                      </td>
                    </tr>
                    <tr className="table_data">
                      <td>
                        <p> Volatile rate at 105°C</p>
                      </td>
                      <td>
                        <p className="margin_left">{"<"}0.5</p>
                      </td>
                    </tr>
                    <tr className="table_data">
                      <td>
                        <p> Inorganic coatingg</p>
                      </td>
                      <td>
                        <p className="margin_left">Zr AI</p>
                      </td>
                    </tr>
                    <tr className="table_data">
                      <td>
                        <p> Organic treatment</p>
                      </td>
                      <td>
                        <p className="margin_left">Yes</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div> */}
        </div>
      </section>

      {/* <TableOld /> */}
      <Table />
      <section className="products_sec4">
        <h2 className="subheading" data-aos="fade-up">
          Helpful Resources
        </h2>
        <div className="resource_wrapper">
          <div className="my_container">
            <div className="row">
              <div className="col-sm-6 col-md-4 p-0">
                <Link to={contactusURL}>
                  <div
                    className="resource_box"
                    style={{ minHeight: boxHeight }}
                    data-aos="fade-up"
                  >
                    <img
                      width={62}
                      height={54}
                      src={mailpro}
                      alt="mail icon"
                      className="icon mail_icon"
                      loading="lazy"
                    />
                    <h3 className="subheading">Request a Sample</h3>
                  </div>
                </Link>
              </div>
              <div className="col-sm-6 col-md-4 p-0">
                <a href="">
                  <div
                    className="resource_box"
                    style={{ minHeight: boxHeight }}
                    data-aos="fade-up"
                  >
                    <img
                      width={52}
                      height={65}
                      src={docpro}
                      alt="doc icon"
                      className="icon doc_icon"
                      loading="lazy"
                    />
                    <h4 className="subheading">
                      Safety Data Sheets
                      <br />
                      <span>(SDS)</span>
                    </h4>
                  </div>
                </a>
              </div>
              <div className="col-sm-6 col-md-4 p-0" ref={boxRef}>
                <a href="">
                  <div
                    className="resource_box"
                    style={{ minHeight: boxHeight }}
                    data-aos="fade-up"
                  >
                    <img
                      width={52}
                      height={65}
                      src={docpro}
                      alt="doc icon"
                      className="icon doc_icon"
                      loading="lazy"
                    />
                    <h5 className="subheading">
                      Technical Data Sheets
                      <br />
                      <span>(TDS)</span>
                    </h5>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Enquiry />
      <Sticky />
      <Whatsapp />
      <Footer />
    </>
  );
};

export default Products;
