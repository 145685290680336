import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import {
  aboutusURL,
  contactusURL,
  faqURL,
  homeURL,
  industriesURL,
  pageNotFound,
  privacyURL,
  productsURL,
  termsURL,
  thanyouURL,
  comingsoonURL,
  newsRoomURL,
  newsInnerURL,
} from "./helpers/paths";
import Homepage from "./pages/Homepage/Homepage";
import Aboutus from "./pages/Aboutus/Aboutus";
import Industries from "./pages/Industries/Industries";
import Products from "./pages/Products/Products";
import Thankyou from "./pages/Thankyou/Thankyou";
import Notfound from "./pages/Notfound/Notfound";
import Faq from "./pages/FAQ/Faq";
import Contactus from "./pages/Contactus/Contactus";
import Terms from "./pages/Terms/Terms";
import Privacypolicy from "./pages/Privacypolicy/Privacyplicy";
import Comingsoon from "./pages/ComingSoonPage/Comingsoon";
import NewsListing from "./pages/NewsListing/NewsListing";
import NewsInner from "./pages/Newsinner/Newsinner";

const Routing = () => {
  return (
    <>
      <Routes>
        <Route path={homeURL} element={<Homepage />} />
        <Route path={aboutusURL} element={<Aboutus />} />
        <Route path={industriesURL} element={<Industries />} />
        <Route path="/product/:slug" element={<Products />} />
        {/* <Route path={newsRoomURL} element={<NewsListing />} /> */}
        <Route path={newsInnerURL} element={<NewsInner />} />
        <Route path={contactusURL} element={<Contactus />} />
        <Route path={termsURL} element={<Terms />} />
        <Route path={privacyURL} element={<Privacypolicy />} />
        <Route path={faqURL} element={<Faq />} />
        <Route path={thanyouURL} element={<Thankyou />} />
        <Route path={pageNotFound} element={<Notfound />} />
        <Route path="*" element={<Navigate to="/404" />} />
        <Route path={comingsoonURL} element={<Comingsoon />} />
      </Routes>
    </>
  );
};

export default Routing;
