import React from "react";
import "./Whatsapp.scss";
import { Whatspp } from "../../source";


const Whatsapp = () => {
    return (
        <>
        <section className="whatsapp_sec1">
            <a href="https://wa.me/+971552563311" target="_blank" className="whatsapp_link">
                <img src={Whatspp} alt="" className="whatsapp_img"/>
            </a>
        </section>
        </>
    );
};

export default Whatsapp;