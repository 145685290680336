import React, { useEffect, useState } from "react";
import "./footer.scss";
import {
  aboutusURL,
  faqURL,
  homeURL,
  industriesURL,
  privacyURL,
  productsURL,
  termsURL,
} from "../../helpers/paths";
import { blackArrow, titaxlogo } from "../../source";
import { Link, NavLink } from "react-router-dom";
import { useWindowSize } from "react-use";
// import { Accordion } from "react-bootstrap";

const Footer = () => {
  const { width } = useWindowSize();
  const [index, setIndex] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [clicked, setClicked] = useState(0);
  const today = new Date();
  const [isEnquire, setIsEnquire] = useState(false);

  // const handlePlusClick = (i) => {
  //   if (index === false) {
  //     setIndex(i);
  //   } else {
  //     setIndex(!index);
  //   }
  // };

  const scrollHandler = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const toggle = (i) => {
    if (clicked === i) {
      //if clicked question is already active, then close it
      return setClicked(null);
    }
    setClicked(i);
  };

  const mbMenuArr = [
    {
      linkName: "Quick Link",
      subMenu: [
        {
          redirectLink: aboutusURL,
          subLinkName: "About Us",
        },
        {
          redirectLink: industriesURL,
          subLinkName: "Applications & Services",
        },
        {
          redirectLink: "/product/r-504",
          subLinkName: "Products",
        },
      ],
    },
    {
      linkName: "Legalities",
      subMenu: [
        // {
        //   redirectLink: faqURL,
        //   subLinkName: "FAQ",
        // },
        {
          redirectLink: termsURL,
          subLinkName: "Terms & Conditions",
        },
        {
          redirectLink: privacyURL,
          subLinkName: "Privacy Policy",
        },
      ],
    },
  ];

  // const mbMenuList = mbMenuArr.map((footerMenu, i) => (
  //   <div className="col-md-3 mbfooter_col" key={i}>
  //     <div className="mb_link_wrapper">
  //       <div className="mb_link_main_wrapper">
  //         <div className="mb_route_text">
  //           {footerMenu.linkName}
  //           <span
  //             className={`plus_span ${index ? "plus_span" : null}`}
  //             onClick={() => {
  //               handlePlusClick(i);
  //             }}
  //           >
  //             {index === i ? "-" : "+"}
  //           </span>
  //         </div>
  //       </div>
  //     </div>

  //     <div
  //       className={`box_content_wrapper ${
  //         index === i ? "box_content_active" : null
  //       }`}
  //     >
  //       {footerMenu.subMenu.map((subMenu, i) => (
  //         <div className="link_name_wrapper" key={i}>
  //           <NavLink to={subMenu.redirectLink} className="link_name">
  //             {subMenu.subLinkName}
  //           </NavLink>
  //         </div>
  //       ))}
  //     </div>
  //   </div>
  // ));

  const mbMenuList = mbMenuArr.map((footerMenu, i) => (
    <div className="col-md-3 mbfooter_col" key={i}>
      <div className="mb_link_wrapper">
        <div className="mb_link_main_wrapper">
          <div className="mb_route_text" onClick={() => toggle(i)}>
            {footerMenu.linkName}
            {/* <img src={clicked == i ? minus : plus } alt=""  onClick={ () => toggle(i) }/> */}
            <span
              // className={`plus_span ${index ? "plus_span" : null}`}
              className="plus_span"
              // onClick={() => toggle(i)}
            >
              {clicked === i ? "-" : "+"}
            </span>
          </div>
        </div>
      </div>

      <div className={`box_content_wrapper`}>
        {clicked == i
          ? footerMenu.subMenu.map((subMenu, i) => (
              <div className="link_name_wrapper" key={i}>
                <NavLink to={subMenu.redirectLink} className="link_name">
                  {subMenu.subLinkName}
                </NavLink>
              </div>
            ))
          : null}
      </div>
    </div>
  ));

  useEffect(() => {
    if (window.location.pathname === "/thank-you" ||
    window.location.pathname === "/404") {
      setIsEnquire(true);
    } else {
      setIsEnquire(false);
    }
  });

  return (
    <>
      <section className="footer_sec1">
        <div className="my_container">
          <div className="row footer_row">
            <div className="col-md-3 footer_col">
              <div className="logo_wrapper">
                <Link to={homeURL}>
                  <img src={titaxlogo} alt="logo" className="footer_logo" />
                </Link>
              </div>
            </div>
            {width > 767 ? (
              <>
                <div className="col-md-3 footer_col">
                  <div className="links_wrapper">
                    <h4 className="link_heading">Quick Link</h4>
                    <ul className="link_list">
                      <li>
                        <Link className="link" to={aboutusURL}>
                          About Us
                        </Link>
                      </li>
                      <li>
                        <Link className="link" to={industriesURL}>
                          Application & Services
                        </Link>
                      </li>
                      <li>
                        <Link className="link" to="/product/r-504">
                          Products
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-md-3 footer_col">
                  <div className="links_wrapper">
                    <h4 className="link_heading">Legalities</h4>
                    <ul className="link_list">
                      {/* <li>
                        <Link className="link" to={faqURL}>
                          FAQ
                        </Link>
                      </li> */}
                      <li>
                        <Link className="link" to={termsURL}>
                          Terms & Conditions
                        </Link>
                      </li>
                      <li>
                        <Link className="link" to={privacyURL}>
                          Privacy Policy
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </>
            ) : (
              <div className={`mb_menu ${openMenu ? "mb_menu_active" : null}`}>
                <div className="mb_links_wrapper_cont">{mbMenuList}</div>
              </div>
            )}

            <div className="col-md-3 footer_col">
              <div className="links_wrapper">
                <h4 className="link_heading link_heading1">Contact</h4>
                <ul className="link_list1">
                  <li>
                    <a className="link1" href="javascript:void(0)">
                      Get in touch with us for any business related queries
                    </a>
                  </li>
                  <li>
                    <a className="link1" href="tel:+971552563311">
                      +971 55 256 3311
                    </a>
                  </li>
                  <li>
                    <a className="link1" href="mailto:sales@titaxtitanium.com">
                      sales@titaxtitanium.com
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            {/* scroll to top start */}
            <div className="scroll_wrap" onClick={scrollHandler}>
              <img
                className="scrollup_img"
                src={blackArrow}
                alt="arrow"
                loading="lazy"
                width="51"
                height="51"
              />
            </div>
            {/* scroll to top end */}
          </div>
        </div>
      </section>

      <section className={`footer_sec2 ${isEnquire ? "enquire" : ""}`}>
        <div className="copy_right_container">
          <p className="copyright_text">
            {/* Copyright {today.getFullYear()} Titax Pvt. Ltd. | */}
            Copyright {today.getFullYear()} Titax Titanium FZ-LLC |
            <a
              href="https://www.togglehead.in/"
              target="_blank"
              rel="noreferrer"
              className="copyright_crafted"
            >
              {" "}
              Crafted by Togglehead
            </a>
          </p>
        </div>
      </section>
    </>
  );
};

export default Footer;
